import { useEffect, useState } from 'react';
import {
    CollectionReference,
    DocumentData,
    Query,
    collection,
    getCountFromServer,
    query,
} from 'firebase/firestore';
import { db } from '../../firebase';

interface Props {
    path: string;
    query?: (ref: CollectionReference) => Query<DocumentData>;
}

const useCollectionCount = (props: Props) => {
    const { path, query: queryConstrains } = props;
    const [count, setCount] = useState(0);
    const ref = collection(db, path);
    const finalQuery = queryConstrains ? queryConstrains(ref) : query(ref);

    useEffect(() => {
        (async () => {
            const result = await getCountFromServer(finalQuery);
            setCount(result.data().count);
        })();
    });

    return count;
};

export default useCollectionCount;
