import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

const isValidPhoneNumber = (value: string) => {
    const number = phoneUtil.parseAndKeepRawInput(value, 'US');
    return phoneUtil.isValidNumber(number);
};

export default isValidPhoneNumber;
