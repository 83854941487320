import { IconProps } from './types';

const Pencil = ({ title, className }: IconProps) => {
    return (
        <svg className={className} width={14} height={13} viewBox="0 0 14 13" fill="none">
            <title>{title}</title>
            <path
                d="M10.0779 1.00136C10.2368 0.842407 10.4255 0.716321 10.6332 0.630298C10.8409 0.544275 11.0635 0.5 11.2883 0.5C11.513 0.5 11.7356 0.544275 11.9433 0.630298C12.151 0.716321 12.3397 0.842407 12.4986 1.00136C12.6576 1.16031 12.7837 1.34901 12.8697 1.55669C12.9557 1.76436 13 1.98695 13 2.21174C13 2.43653 12.9557 2.65912 12.8697 2.8668C12.7837 3.07447 12.6576 3.26317 12.4986 3.42212L4.32855 11.5922L1 12.5L1.90779 9.17145L10.0779 1.00136Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Pencil;
