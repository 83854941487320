import { ApiKey } from '@transactional-message-tool/shared';
import useQuery from './useQuery';

const useApiKeys = (appId: string) => {
    return useQuery<undefined, ApiKey[]>({
        path: `/apps/${appId}/apikeys`,
    });
};

export default useApiKeys;
