import { Input } from 'react-rainbow-components';
import { isRequired, Field } from '@rainbow-modules/forms';
import { FormContainer } from './styled';

const InputField = (props: Record<string, unknown>) => {
    return <Input labelAlignment="left" borderRadius="semi-rounded" {...props} />;
};

const Form = () => {
    return (
        <FormContainer>
            <Field
                name="name"
                component={InputField}
                label="Friendly Name"
                validate={isRequired()}
                required
                placeholder="Enter a name for this key"
                className="rainbow-m-bottom_large"
            />
        </FormContainer>
    );
};

export default Form;
