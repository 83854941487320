import { ButtonIcon } from 'react-rainbow-components';
import { Trash } from '@rainbow-modules/icons';
import { Pencil, Message } from 'components/icons';
import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import useMutation from 'data/hooks/useMutation';
import { confirmModal } from '@rainbow-modules/app';
import { PhoneNumberParts } from '@transactional-message-tool/shared';
import useCreateEditContactModal from 'data/hooks/useCreateEditContactModal';
import { HighlightProps } from 'react-instantsearch-core';
import {
    ContactItemContainer,
    Column,
    ContactName,
    PhoneNumber,
    ContactLabel,
    ContactValue,
    ContactActions,
    TrashIconDelete,
} from '../styled';

interface Props {
    className?: string;
    id: string;
    firstName: string | ReactElement<HighlightProps>;
    lastName: string | ReactElement<HighlightProps>;
    phoneNumber: string | ReactElement<HighlightProps>;
    createdAt: string | ReactElement<HighlightProps>;
    phoneNumberParts: PhoneNumberParts;
    onEdit?: () => void;
}

const Contact = (props: Props) => {
    const { className, onEdit } = props;
    const { firstName, lastName, phoneNumber, createdAt, id, phoneNumberParts } = props;
    const { appId, groupId } = useParams() as { appId: string; groupId: string };
    const initialValues = {
        firstName,
        lastName,
        phone: {
            phone: phoneNumberParts.nationalNumber,
            isoCode: phoneNumberParts.isoCode,
            countryCode: phoneNumberParts.countryCode,
            formattedNumber: phoneNumberParts.formattedNumber,
        },
    };

    const { openCreateEditContact } = useCreateEditContactModal({
        appId,
        groupId,
        action: 'edit',
        initialValues,
        id,
    });

    const deleteContact = useMutation({
        method: 'delete',
        successMessage: 'Contact removed successfully',
    });

    const onRemove = async () => {
        const result = await confirmModal({
            header: 'Delete Contact',
            question: "This contact will be deleted immediately. You can't undo this action.",
            okButtonLabel: 'Delete',
            variant: 'destructive',
            icon: <TrashIconDelete />,
        });
        if (result) {
            deleteContact({ path: `/apps/${appId}/groups/${groupId}/contacts/${id}` });
        }
    };

    return (
        <ContactItemContainer className={className}>
            <Column>
                <ContactName>
                    {firstName}
                    {` `}
                    {lastName}
                </ContactName>
                <PhoneNumber>{phoneNumber}</PhoneNumber>
            </Column>
            <Column>
                <ContactLabel>Created At:</ContactLabel>
                <ContactValue>{createdAt}</ContactValue>
            </Column>
            <Column>
                <ContactLabel>Carrier:</ContactLabel>
                <ContactValue>AT&T</ContactValue>
            </Column>
            <ContactActions>
                <ButtonIcon borderRadius="semi-rounded" icon={<Message />} />
                <ButtonIcon
                    borderRadius="semi-rounded"
                    icon={<Pencil />}
                    onClick={onEdit || openCreateEditContact}
                />
                <ButtonIcon borderRadius="semi-rounded" icon={<Trash />} onClick={onRemove} />
            </ContactActions>
        </ContactItemContainer>
    );
};

export default Contact;
