import { PhoneNumberUtil, PhoneNumberFormat, PhoneNumber } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

const formatPhoneNumber = (phoneNumber: string) => {
    const phoneNumberInstance: PhoneNumber = phoneUtil.parse(phoneNumber, 'US');
    return phoneUtil.format(phoneNumberInstance, PhoneNumberFormat.E164);
};

export default formatPhoneNumber;
