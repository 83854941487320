import styled from 'styled-components';
import { color } from 'theme';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

export const Title = styled.h3`
    font-size: 22px;
    color: ${color('text.main')};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    margin-bottom: 8px;
`;

export const Description = styled.p`
    display: block;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    font-family: Lato, Helvetica, sans-serif;
    color: ${color('text.label')};
    max-width: 600px;
    margin-bottom: 24px;
`;
