import { IconProps } from './types';

const Grid = ({ className }: IconProps) => {
    return (
        <svg
            className={className}
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.66406 2.5C2.66406 2.22386 2.88792 2 3.16406 2H8.9974C9.27354 2 9.4974 2.22386 9.4974 2.5V8.33333C9.4974 8.60948 9.27354 8.83333 8.9974 8.83333H3.16406C2.88792 8.83333 2.66406 8.60948 2.66406 8.33333V2.5ZM3.66406 3V7.83333H8.4974V3H3.66406Z"
                fill="#6750A4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.8307 2.5C11.8307 2.22386 12.0545 2 12.3307 2H18.164C18.4402 2 18.664 2.22386 18.664 2.5V8.33333C18.664 8.60948 18.4402 8.83333 18.164 8.83333H12.3307C12.0545 8.83333 11.8307 8.60948 11.8307 8.33333V2.5ZM12.8307 3V7.83333H17.664V3H12.8307Z"
                fill="#6750A4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.8307 11.6665C11.8307 11.3904 12.0545 11.1665 12.3307 11.1665H18.164C18.4402 11.1665 18.664 11.3904 18.664 11.6665V17.4998C18.664 17.776 18.4402 17.9998 18.164 17.9998H12.3307C12.0545 17.9998 11.8307 17.776 11.8307 17.4998V11.6665ZM12.8307 12.1665V16.9998H17.664V12.1665H12.8307Z"
                fill="#6750A4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.66406 11.6665C2.66406 11.3904 2.88792 11.1665 3.16406 11.1665H8.9974C9.27354 11.1665 9.4974 11.3904 9.4974 11.6665V17.4998C9.4974 17.776 9.27354 17.9998 8.9974 17.9998H3.16406C2.88792 17.9998 2.66406 17.776 2.66406 17.4998V11.6665ZM3.66406 12.1665V16.9998H8.4974V12.1665H3.66406Z"
                fill="#6750A4"
            />
        </svg>
    );
};

export default Grid;
