import { IconProps } from './types';

const PlusCircle = ({ className }: IconProps) => {
    return (
        <svg
            className={className}
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.664 2.1665C6.33779 2.1665 2.83069 5.67361 2.83069 9.99984C2.83069 14.3261 6.33779 17.8332 10.664 17.8332C14.9903 17.8332 18.4974 14.3261 18.4974 9.99984C18.4974 5.67361 14.9903 2.1665 10.664 2.1665ZM1.83069 9.99984C1.83069 5.12132 5.78551 1.1665 10.664 1.1665C15.5425 1.1665 19.4974 5.12132 19.4974 9.99984C19.4974 14.8784 15.5425 18.8332 10.664 18.8332C5.78551 18.8332 1.83069 14.8784 1.83069 9.99984Z"
                fill="#6750A4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.6641 6.1665C10.9402 6.1665 11.1641 6.39036 11.1641 6.6665V13.3332C11.1641 13.6093 10.9402 13.8332 10.6641 13.8332C10.3879 13.8332 10.1641 13.6093 10.1641 13.3332V6.6665C10.1641 6.39036 10.3879 6.1665 10.6641 6.1665Z"
                fill="#6750A4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.83069 10C6.83069 9.72386 7.05455 9.5 7.33069 9.5H13.9974C14.2735 9.5 14.4974 9.72386 14.4974 10C14.4974 10.2761 14.2735 10.5 13.9974 10.5H7.33069C7.05455 10.5 6.83069 10.2761 6.83069 10Z"
                fill="#6750A4"
            />
        </svg>
    );
};

export default PlusCircle;
