const getInitials = (name?: string | null) => {
    if (name) {
        const arr = name.split(' ');
        const firtsNameInitial = arr[0].charAt(0).toUpperCase();
        const lastNameInitial = arr?.[1]?.charAt(0).toUpperCase();
        if (firtsNameInitial && lastNameInitial) {
            return `${firtsNameInitial}${lastNameInitial}`;
        }
        return firtsNameInitial;
    }
    return undefined;
};

export default getInitials;
