import { IconProps } from './types';

const KeyImage = ({ title, className }: IconProps) => {
    return (
        <svg className={className} width={120} height={120} viewBox="0 0 120 120" fill="none">
            <title>{title}</title>

            <path
                opacity="0.4"
                d="M81.672 10.1309H38.327C21.382 10.1309 10.002 22.0209 10.002 39.7159V80.5509C10.002 98.2409 21.382 110.131 38.327 110.131H81.667C98.612 110.131 110.002 98.2409 110.002 80.5509V39.7159C110.002 22.0209 98.617 10.1309 81.672 10.1309"
                fill="#CCC2DC"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M56.5709 56.375H85.0709C87.1209 56.375 88.8209 58.075 88.8209 60.125V69.375C88.8209 71.475 87.1209 73.125 85.0709 73.125C82.9709 73.125 81.3209 71.475 81.3209 69.375V63.875H74.6709V69.375C74.6709 71.475 72.9709 73.125 70.9209 73.125C68.8209 73.125 67.1709 71.475 67.1709 69.375V63.875H56.5709C54.9709 69.225 50.0709 73.125 44.2209 73.125C37.0209 73.125 31.1709 67.325 31.1709 60.125C31.1709 52.975 37.0209 47.125 44.2209 47.125C50.0709 47.125 54.9709 51.025 56.5709 56.375ZM38.6709 60.125C38.6709 63.175 41.1709 65.625 44.2209 65.625C47.2209 65.625 49.7209 63.175 49.7209 60.125C49.7209 57.075 47.2209 54.625 44.2209 54.625C41.1709 54.625 38.6709 57.075 38.6709 60.125Z"
                fill="#6750A4"
            />
        </svg>
    );
};

export default KeyImage;
