import { useOpenModal } from '@rainbow-modules/hooks';
import { App, AppCreate } from '@transactional-message-tool/shared';
import useMutation from 'data/hooks/useMutation';
import ModalTitle from 'components/ModalTitle';
import { APP_MODAL } from '../../constants';

const useCreateApps = () => {
    const [openModal, closeModal] = useOpenModal(APP_MODAL);
    const createApp = useMutation<AppCreate, App>({
        method: 'post',
        onSuccess: closeModal,
        successMessage: 'App created successfully',
    });
    const openCreateApp = () =>
        openModal({
            title: ModalTitle({ title: 'Create new Application' }),
            submitButtonLabel: 'Create Application',
            onSubmit: (values: AppCreate) => {
                const { areaCode, states, ...rest } = values;
                const data = {
                    ...rest,
                };
                createApp({ path: `/apps`, body: data });
            },
        });

    return openCreateApp;
};

export default useCreateApps;
