import styled from 'styled-components';
import { color } from 'theme';

export const TitleContainer = styled.div`
    display: flex;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    align-items: center;
    font-family: 'Lato';
    font-style: normal;
`;

export const DescriptionContainer = styled.div`
    display: flex;
    font-weight: 400;
    font-size: 14px;
    margin-top: 8px;
    color: ${color('text.header')};
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 18px 40px;
`;
