import useCollectionCount from 'data/hooks/useCollectionCount';
import { CollectionReference, query, where } from 'firebase/firestore';
import { StyledColumnWrapper } from '../styled';
import { GroupCountColumnProps } from '../types';

const GroupCountColumn = ({ row, className }: GroupCountColumnProps) => {
    const queryConstraint = (ref: CollectionReference) => query(ref, where('removed', '==', false));
    const count = useCollectionCount({ path: `apps/${row?.id}/groups`, query: queryConstraint });
    return <StyledColumnWrapper className={className}>{count}</StyledColumnWrapper>;
};

export default GroupCountColumn;
