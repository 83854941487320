import {
    GroupItemContainer,
    GroupItemName,
    GroupItemDescription,
    StyledChevronRight,
    GroupLeftContent,
} from './styled';

interface Props {
    className?: string;
    groupName?: string;
    description?: string;
    onClick?: () => void;
    isSelected?: boolean;
}

const GroupItem = (props: Props) => {
    const { className, groupName, description, onClick, isSelected } = props;

    return (
        <GroupItemContainer isSelected={isSelected} className={className} onClick={onClick}>
            <GroupLeftContent>
                <GroupItemName isSelected={isSelected}>{groupName}</GroupItemName>
                <GroupItemDescription isSelected={isSelected}>{description}</GroupItemDescription>
            </GroupLeftContent>
            <StyledChevronRight />
        </GroupItemContainer>
    );
};

export default GroupItem;
