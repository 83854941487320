import { Hit } from 'react-instantsearch-core';
import formatter from 'data/services/date/formatter';
import { Contact as ContactType } from '@transactional-message-tool/shared';
import { useParams } from 'react-router-dom';
import useCreateEditContactModal from 'data/hooks/useCreateEditContactModal';
import { StyledHighlight } from '../styled';
import Contact from './contact';

interface Props {
    hit: Hit<ContactType>;
}

interface AlgoliaFirestoreTimestamp {
    _seconds: number;
    _nanoseconds: number;
}

const getContactId = (id: string) => {
    if (id.includes('/')) {
        return id.split('/').pop();
    }
    return id;
};

const AlgoliaContact = (props: Props) => {
    const { appId, groupId } = useParams() as { appId: string; groupId: string };
    const { hit } = props;
    const { firstName, lastName, phoneNumberParts } = hit;
    const { objectID } = hit;
    const createdAt = formatter.format(
        // eslint-disable-next-line no-underscore-dangle
        (hit.createdAt as unknown as AlgoliaFirestoreTimestamp)._seconds * 1000,
    );
    const initialValues = {
        firstName,
        lastName,
        phone: {
            phone: phoneNumberParts.nationalNumber,
            isoCode: phoneNumberParts.isoCode,
            countryCode: phoneNumberParts.countryCode,
            formattedNumber: phoneNumberParts.formattedNumber,
        },
    };

    const { openCreateEditContact } = useCreateEditContactModal({
        appId,
        groupId,
        action: 'edit',
        initialValues,
        id: getContactId(objectID),
    });

    return (
        <Contact
            id={objectID}
            firstName={<StyledHighlight attribute="firstName" hit={hit} />}
            lastName={<StyledHighlight attribute="lastName" hit={hit} />}
            phoneNumber={<StyledHighlight attribute="phoneNumber" hit={hit} />}
            createdAt={createdAt}
            phoneNumberParts={phoneNumberParts}
            onEdit={openCreateEditContact}
        />
    );
};

export default AlgoliaContact;
