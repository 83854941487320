import { Contact } from '@transactional-message-tool/shared';
import isValidPhoneNumber from 'helpers/isValidPhoneNumber';

interface ErrorProps {
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
}

const isContactValid = (record: Contact) => {
    const error: ErrorProps = {};
    if (record.firstName === undefined) {
        error.firstName = 'First name is required';
    }
    if (record.phoneNumber === undefined) {
        error.phoneNumber = 'Phone number is required';
    }
    if (record.phoneNumber !== undefined && !isValidPhoneNumber(record.phoneNumber)) {
        error.phoneNumber =
            'This phone number is not valid. The accepted format is +15555555555 or 5555555555.';
    }
    if (record.lastName === undefined) {
        error.lastName = 'Last name is required';
    }
    return error;
};

export default isContactValid;
