import { Button, LoadingShape } from 'react-rainbow-components';
import styled, { css } from 'styled-components';
import { color } from 'theme';

export const HeaderTitle = styled.h1`
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: ${color('text.main')};
    font-family: Lato, Arial, Helvetica, sans-serif;
    margin-bottom: 15px;
`;

interface ItemProps {
    isSelected?: boolean;
}

export const ItemContainer = styled.li<ItemProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 8px 14px;
    border: 1px solid ${color('border.disabled')};
    border-radius: 10px;
    margin-bottom: 8px;

    ${(props) =>
        props.isSelected &&
        css`
            background-color: ${color('brand.light')};
            border: 1px solid ${color('brand.light')};
        `}
    &:hover {
        background-color: ${color('brand.light')};
        border: 1px solid ${color('brand.light')};
        cursor: pointer;
    }
`;

export const LeftContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const ItemName = styled.h3<ItemProps>`
    font-size: 16px;
    color: ${color('text.main')};
    width: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 400;

    ${(props) =>
        props.isSelected &&
        css`
            color: ${color('brand.dark')};
        `}
`;

export const ItemDescription = styled.p<ItemProps>`
    font-size: 13px;
    color: ${color('text.header')};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 2px;

    ${(props) =>
        props.isSelected &&
        css`
            color: ${color('brand.main')};
            opacity: 0.8;
        `}
`;

export const Container = styled.div`
    overflow: scroll;
    max-height: 140px;
    padding-right: 15px;
    margin-bottom: 10px;
`;

export const SelectPhoneNumberContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
`;

export const StateErrorMessage = styled.div`
    color: ${color('error.main')};
    font-size: 14px;
    line-height: 18px;
    font-family: Lato, Arial, Helvetica, sans-serif;
    margin-bottom: 10px;
`;

export const StyledButton = styled(Button)`
    margin-top: 23px;
`;

export const IsLoadingCardContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 8px 14px;
    border: 1px solid ${color('border.disabled')};
    border-radius: 10px;
    gap: 16px;
`;

export const IsLoadingItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    min-width: 33%;
`;

export const StyledLoadingShapeSmall = styled(LoadingShape)`
    max-width: 100px;
    height: 12px;
`;

export const StyledLoadingShapeMediumLarge = styled(LoadingShape)`
    max-width: 200px;
    height: 16px;
`;
