import { App } from '@transactional-message-tool/shared';
import getInitials from 'helpers/getInitials';
import {
    StyledApplicationAvatar,
    StyledApplicationId,
    StyledApplicationName,
    StyledApplicationNameContainer,
    StyledColumnWrapper,
    StyledLink,
} from '../styled';

interface Props {
    row?: App;
}

const ApplicationColumn = ({ row }: Props) => {
    const { name, id, defaultGroupId } = row as App;
    return (
        <StyledColumnWrapper>
            <StyledLink to={`/app/${id}/groups/${defaultGroupId}`}>
                <StyledApplicationAvatar
                    assistiveText={name}
                    title={name}
                    initials={getInitials(name)}
                />
                <StyledApplicationNameContainer>
                    <StyledApplicationName>{name}</StyledApplicationName>
                    <StyledApplicationId>{id}</StyledApplicationId>
                </StyledApplicationNameContainer>
            </StyledLink>
        </StyledColumnWrapper>
    );
};

export default ApplicationColumn;
