import { useNavigate } from 'react-router-dom';
import { useCurrentUserState } from '@rainbow-modules/firebase-hooks';
import { Avatar, MenuItem, MenuDivider, RenderIf } from 'react-rainbow-components';
import logout from 'data/services/auth/logout';
import { Logout } from '@rainbow-modules/icons';
import getInitials from 'helpers/getInitials';
import AppSelector from 'components/AppSelector';
import { RetalkableLogo } from 'components/icons';
import {
    Container,
    RightContainer,
    AvatarContainer,
    StyledAvatarMenu,
    UserInformationContainer,
    UserInformation,
    UserName,
    UserEmail,
    MenuIcon,
    LeftContainer,
} from './styled';

interface TopbarProps {
    isInner?: boolean;
}

const Topbar = (props: TopbarProps) => {
    const { isInner } = props;
    const navigate = useNavigate();
    const { user } = useCurrentUserState();
    const { photoURL, displayName, email } = user || {};
    const initials = getInitials(displayName);
    return (
        <Container>
            <LeftContainer>
                <RenderIf isTrue={!isInner}>
                    <RetalkableLogo />
                </RenderIf>
                <RenderIf isTrue={isInner}>
                    <AppSelector />
                </RenderIf>
            </LeftContainer>
            <RightContainer>
                <AvatarContainer>
                    <StyledAvatarMenu
                        avatarSize="medium"
                        initials={initials}
                        assistiveText={displayName}
                        title={displayName}
                        src={photoURL}
                    >
                        <UserInformationContainer>
                            <Avatar
                                initials={initials}
                                assistiveText={displayName}
                                title={displayName}
                                src={photoURL}
                            />
                            <UserInformation>
                                <UserName>{displayName}</UserName>
                                <UserEmail>{email}</UserEmail>
                            </UserInformation>
                        </UserInformationContainer>
                        <MenuDivider />
                        <MenuItem
                            icon={<MenuIcon />}
                            label="Personal Information"
                            onClick={() => navigate('/user-profile')}
                        />
                        <MenuItem
                            icon={<MenuIcon as={Logout} className="rainbow-m-left_x-small" />}
                            label="Logout"
                            onClick={logout}
                        />
                    </StyledAvatarMenu>
                </AvatarContainer>
            </RightContainer>
        </Container>
    );
};

export default Topbar;
