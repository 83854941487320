import { App } from '@transactional-message-tool/shared';
import { ButtonIcon } from 'react-rainbow-components';
import { Edit, Trash } from '@rainbow-modules/icons';
import { confirmModal } from '@rainbow-modules/app';
import useMutation from 'data/hooks/useMutation';
import useUpdateApps from 'data/hooks/useUpdateApps';
import { StyledActions, TrashIconDelete } from '../styled';

interface Props {
    row?: App;
}

const ActionsColumn = ({ row }: Props) => {
    const editApp = useUpdateApps();
    const removeApp = useMutation({
        method: 'delete',
        successMessage: 'Application removed successfully',
    });

    const onRemove = async (app: App) => {
        const { id } = app;
        const result = await confirmModal({
            icon: <TrashIconDelete />,
            variant: 'destructive',
            header: 'Delete Application',
            question: "This application will be deleted immediately. You can't undo this action.",
            okButtonLabel: 'Delete',
        });
        if (result) {
            removeApp({ path: `/apps/${id}` });
        }
    };

    return (
        <StyledActions>
            <ButtonIcon
                borderRadius="semi-rounded"
                variant="base"
                icon={<Edit />}
                onClick={() => editApp(row as App)}
            />
            <ButtonIcon
                borderRadius="semi-rounded"
                variant="base"
                icon={<Trash />}
                onClick={() => onRemove(row as App)}
            />
        </StyledActions>
    );
};

export default ActionsColumn;
