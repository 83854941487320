import { ApiKey } from '@transactional-message-tool/shared';
import { StyledColumnWrapper } from '../styled';

interface Row extends ApiKey {
    new?: boolean;
}

interface Props {
    row?: Row;
}

const CreatedByColumn = ({ row }: Props) => {
    const { createdBy } = row || {};
    const { displayName, email } = createdBy || {};
    return (
        <StyledColumnWrapper new={row?.new}>
            {displayName} ({email})
        </StyledColumnWrapper>
    );
};

export default CreatedByColumn;
