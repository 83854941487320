import { OptionContainer, OptionName, StyledChevronRight } from './styled';

interface Props {
    className?: string;
    name?: string;
    onClick?: () => void;
    isSelected?: boolean;
}

const Option = (props: Props) => {
    const { className, name, onClick, isSelected } = props;

    return (
        <OptionContainer className={className} onClick={onClick} isSelected={isSelected}>
            <OptionName>{name}</OptionName>
            <StyledChevronRight />
        </OptionContainer>
    );
};

export default Option;
