import {
    Button,
    ButtonMenu,
    ImportRecordsFlow,
    MenuItem,
    RenderIf,
} from 'react-rainbow-components';
import { UniversalFormModal } from '@rainbow-modules/forms';
import { More, Trash } from '@rainbow-modules/icons';
import { Pencil } from 'components/icons';
import { useConnectModal, useMutationFlow, useOpenModal } from '@rainbow-modules/hooks';
import useAlgoliaClient from 'data/hooks/useAlgoliaClient';
import { InstantSearch, Hits } from 'react-instantsearch-dom';
import { useParams } from 'react-router-dom';
import useGroup from 'data/hooks/useGroup';
import useMutation from 'data/hooks/useMutation';
import { GroupCreate, Group } from '@transactional-message-tool/shared';
import { confirmModal } from '@rainbow-modules/app';
import { useState } from 'react';
import useApp from 'data/hooks/useApp';
import { CompleteValue } from 'react-rainbow-components/components/ImportRecordsFlow';
import batchAdd from 'data/hooks/batchContacts';
import Upload from '@rainbow-modules/icons/lib/components/upload';
import useCreateEditContactModal from 'data/hooks/useCreateEditContactModal';
import ModalTitle from 'components/ModalTitle';
import isContactValid from 'helpers/isContactValid';
import LoadingShapes from 'components/LoadingShapes';
import { formatPlural } from 'formatters';
import useCollectionCount from 'data/hooks/useCollectionCount';
import SearchEmptyMessage from '../../../../components/SearchEmptyMessage';
import { getImportContactsSchema } from '../../../../constants';
import GroupForm from '../groupForm';
import AlgoliaContact from './algoliaContact';
import ContactsFromFirestore from './contactsFromFirestore';
import {
    GroupHeader,
    GroupLeftContent,
    DetailsHeaderTitle,
    DetailsHeaderDescription,
    DetailsHeaderRightContent,
    GroupDetailsContainer,
    SearchBoxStyled,
    SearchBoxContainer,
    SearchIcon,
    TrashIconDelete,
    Icon,
} from '../styled';
import ContactForm from '../contactForm';

const GroupFormEdit = () => {
    return <GroupForm action="edit" />;
};

const GroupDetails = () => {
    const connectedModalGroupProps = useConnectModal('edit-group');
    const [openGroupModal, closeGroupModal] = useOpenModal('edit-group');
    const connectedModalProps = useConnectModal('add-edit-contact');
    const connectedImportModalProps = useConnectModal('import-contacts');
    const [openImportContactsModal, closeImportContactsModal] = useOpenModal('import-contacts');
    const algoliaClient = useAlgoliaClient();
    const { appId, groupId } = useParams() as { appId: string; groupId: string };
    const [app] = useApp(appId);
    const [group, isLoading] = useGroup(appId, groupId);
    const [query, setQuery] = useState('');
    const { mutate: importContactMutation } = useMutationFlow({
        mutation: batchAdd,
        successMessage: 'Contacts imported successfully',
        onSuccess: closeImportContactsModal,
        errorMessage: 'Error importing contacts',
    });
    const { openCreateEditContact } = useCreateEditContactModal({
        appId,
        groupId,
        action: 'create',
    });
    const contactsCount = useCollectionCount({ path: `apps/${appId}/groups/${groupId}/contacts` });

    const updateGroup = useMutation<GroupCreate, Group>({
        method: 'patch',
        onSuccess: closeGroupModal,
        successMessage: 'Group updated successfully',
    });

    const deleteGroup = useMutation<GroupCreate, Group>({
        method: 'delete',
        successMessage: 'Group removed successfully',
    });

    const onImportContacts = () => {
        openImportContactsModal();
    };

    const onEdit = () => {
        const { name, externalId, description } = group || {};
        openGroupModal({
            title: ModalTitle({ title: 'Edit Group' }),
            submitButtonLabel: 'Update',
            initialValues: {
                name,
                externalId,
                description,
            },
            onSubmit: (values: GroupCreate) => {
                updateGroup({ path: `/apps/${appId}/groups/${groupId}`, body: values });
            },
        });
    };

    const onRemove = async () => {
        const result = await confirmModal({
            header: 'Delete Group',
            question: "This group will be deleted immediately. You can't undo this action.",
            okButtonLabel: 'Delete',
            variant: 'destructive',
            icon: <TrashIconDelete />,
        });
        if (result) {
            deleteGroup({ path: `/apps/${appId}/groups/${groupId}` });
        }
    };

    const importContacts = (contacts: CompleteValue) => {
        const { data } = contacts;
        if (data?.length === 0) {
            closeImportContactsModal();
            return;
        }
        importContactMutation(appId, groupId, data);
    };

    if (isLoading) return <LoadingShapes />;

    if (algoliaClient) {
        return (
            <>
                <InstantSearch indexName="contacts" searchClient={algoliaClient}>
                    <GroupDetailsContainer>
                        <GroupHeader>
                            <GroupLeftContent>
                                <DetailsHeaderTitle>{group?.name}</DetailsHeaderTitle>
                                <DetailsHeaderDescription>
                                    {formatPlural({
                                        value: contactsCount,
                                        singularSuffix: 'Contact',
                                        pluralSuffix: 'Contacts',
                                    })}
                                </DetailsHeaderDescription>
                            </GroupLeftContent>
                            <DetailsHeaderRightContent>
                                <Button
                                    variant="outline-brand"
                                    borderRadius="semi-rounded"
                                    onClick={openCreateEditContact}
                                >
                                    <Icon />
                                    New Contact
                                </Button>
                                <Button
                                    variant="outline-brand"
                                    borderRadius="semi-rounded"
                                    onClick={onImportContacts}
                                >
                                    <Icon as={Upload} />
                                    Import Contacts
                                </Button>
                                <RenderIf isTrue={groupId !== app?.defaultGroupId}>
                                    <ButtonMenu
                                        buttonVariant="outline-brand"
                                        icon={<More />}
                                        menuSize="x-small"
                                        borderRadius="semi-rounded"
                                    >
                                        <MenuItem
                                            label="Edit Group"
                                            onClick={onEdit}
                                            icon={<Pencil className="rainbow-m-bottom_xx-small" />}
                                        />
                                        <MenuItem
                                            label="Delete Group"
                                            onClick={onRemove}
                                            icon={<Trash className="rainbow-m-bottom_xx-small" />}
                                        />
                                    </ButtonMenu>
                                </RenderIf>
                            </DetailsHeaderRightContent>
                        </GroupHeader>
                        <SearchBoxContainer>
                            <SearchBoxStyled
                                onChange={(event) => {
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    setQuery(event.target.value);
                                }}
                            />
                            <SearchIcon />
                        </SearchBoxContainer>
                        <RenderIf isTrue={query.length > 0}>
                            <Hits hitComponent={AlgoliaContact} />
                            <SearchEmptyMessage />
                        </RenderIf>
                        <RenderIf isTrue={query.length === 0}>
                            <ContactsFromFirestore />
                        </RenderIf>
                        <UniversalFormModal
                            borderRadius="semi-rounded"
                            fields={ContactForm}
                            {...connectedModalProps}
                        />
                        {/* Message when doesn't exist results for the search */}
                        {/* <SearchEmptyMessage value="ter" /> */}
                        <ImportRecordsFlow
                            {...connectedImportModalProps}
                            onRequestClose={closeImportContactsModal}
                            onComplete={(data: Record<string, string>) => importContacts(data)}
                            schema={getImportContactsSchema(groupId, appId)}
                            actionType="add-records"
                            borderRadius="semi-rounded"
                            validateRecordCallback={isContactValid}
                        />
                    </GroupDetailsContainer>
                </InstantSearch>
                <UniversalFormModal fields={GroupFormEdit} {...connectedModalGroupProps} />
            </>
        );
    }
    return null;
};

export default GroupDetails;
