import { useOpenModal } from '@rainbow-modules/hooks';
import { Group, GroupCreate } from '@transactional-message-tool/shared';
import useMutation from 'data/hooks/useMutation';
import ModalTitle from 'components/ModalTitle';
import { GROUP_MODAL } from '../../constants';

const useCreateGroups = (appId: string) => {
    const [openModal, closeModal] = useOpenModal(GROUP_MODAL);
    const createGroup = useMutation<GroupCreate, Group>({
        method: 'post',
        onSuccess: closeModal,
        successMessage: 'Group created successfully',
    });

    const openCreateGroup = () =>
        openModal({
            title: ModalTitle({ title: 'Create new Group' }),
            submitButtonLabel: 'Create Group',
            onSubmit: (values: GroupCreate) => {
                const { areaCode, states, ...rest } = values;
                const data = {
                    ...rest,
                };
                createGroup({ path: `/apps/${appId}/groups`, body: data });
            },
        });
    return openCreateGroup;
};

export default useCreateGroups;
