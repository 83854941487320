import styled, { css } from 'styled-components';
import { ChevronRight, MagnifyingGlass, Plus, Trash } from '@rainbow-modules/icons';
import { Input } from 'react-rainbow-components';
import { SearchBox, Highlight } from 'react-instantsearch-dom';
import { color } from '../../../theme';
import EmptyMessage from '../../../components/EmptyMessage';

export const Container = styled.div`
    padding: 0 32px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-sizing: border-box;
`;

export const GroupHeader = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    flex-wrap: wrap;
`;

export const GroupListTitle = styled.h1`
    font-size: 20px;
    color: ${color('text.main')};
    text-transform: uppercase;
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

interface GroupItemProps {
    isSelected?: boolean;
}
export const GroupItemContainer = styled.li<GroupItemProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 8px 14px;
    border: 1px solid ${color('border.disabled')};
    border-radius: 10px;
    margin-bottom: 8px;

    ${(props) =>
        props.isSelected &&
        css`
            background-color: ${color('brand.light')};
            border: 1px solid ${color('brand.light')};
        `}
    &:hover {
        background-color: ${color('brand.light')};
        border: 1px solid ${color('brand.light')};
        cursor: pointer;
    }
`;

export const GroupLeftContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const GroupItemName = styled.h3<GroupItemProps>`
    font-size: 16px;
    color: ${color('text.main')};
    width: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 400;

    ${(props) =>
        props.isSelected &&
        css`
            color: ${color('brand.dark')};
        `}
`;

export const GroupItemDescription = styled.p<GroupItemProps>`
    font-size: 13px;
    color: ${color('text.header')};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 2px;

    ${(props) =>
        props.isSelected &&
        css`
            color: ${color('brand.main')};
            opacity: 0.8;
        `}
`;

export const StyledChevronRight = styled(ChevronRight)`
    color: ${color('brand.main')};
    flex-shrink: 0;
`;

export const GroupDetailsContainer = styled.div`
    flex-grow: 1;
    margin-left: 24px;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
    padding-top: 24px;
`;

export const StyledSearchInput = styled(Input)`
    width: 60%;
    margin-bottom: 20px;
`;

export const DetailsHeaderTitle = styled.h1`
    font-size: 20px;
    color: ${color('text.main')};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const DetailsHeaderDescription = styled.div`
    font-size: 14px;
    color: ${color('text.header')};
`;

export const DetailsHeaderRightContent = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
`;

export const ContactItemContainer = styled.div`
    display: flex;
    width: 100%;
    padding: 8px 14px;
    border: 1px solid ${color('border.disabled')};
    border-radius: 10px;
    gap: 16px;
    margin-bottom: 5px;
`;

export const Column = styled.div`
    flex: 1;
    height: 100%;
    align-self: center;
    box-sizing: border-box;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const ContactName = styled.h3`
    font-size: 14px;
    color: ${color('text.main')};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const PhoneNumber = styled.h4`
    font-size: 14px;
    color: ${color('text.main')};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const ContactLabel = styled.h5`
    font-size: 12px;
    color: ${color('text.header')};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const ContactValue = styled.h5`
    font-size: 14px;
    color: ${color('text.main')};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const ContactActions = styled.div`
    height: 100%;
    align-self: center;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const SearchBoxContainer = styled.div`
    width: 50%;
    position: relative;
`;

export const SearchIcon = styled(MagnifyingGlass)`
    width: 15px;
    height: 15px;
    position: absolute;
    left: 14px;
    top: 11px;
    color: ${color('text.header')};
`;
// interface SearchBoxI {
//     // onChange: (query: string) => void;
// }
export const SearchBoxStyled = styled(SearchBox)`
    input {
        width: 100%;
        box-sizing: border-box;
        background: #fff;
        border: 1px solid ${color('border.disabled')};
        border-radius: 10px;
        padding: 8px 15px 9px 38px;
        margin-bottom: 12px;
        color: ${color('text.main')};
        font-size: 16px;
        letter-spacing: 0.25px;

        ::placeholder {
            color: ${color('text.header')};
            font-size: 16px;
            letter-spacing: 0.25px;
        }

        &:focus {
            border: 2px solid ${color('brand.main')};
            padding: 7px 14px 8px 37px;
            outline: none;
        }
    }

    button {
        display: none;
    }
`;

export const StyledHighlight = styled(Highlight)`
    .ais-Highlight-highlighted {
        color: #003dff;
        background-color: rgba(30, 89, 255, 0.1);
        font-style: normal;
        font-family: Lato Bold, Helvetica, sans-serif;
    }
`;

export const TrashIconDelete = styled(Trash)`
    width: 60px;
    height: 60px;
    margin: 0 12px 0 0;
    color: ${color('text.main')};
`;

export const StyledNoMessageContainer = styled(EmptyMessage)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-bottom: 56px;
`;

export const StyledPlusIcon = styled(Plus)`
    margin-right: 8px;
`;

export const StyledLink = styled.a`
    color: ${color('text.main')};
    font-weight: 600;
    font-family: Lato, Helvetica, sans-serif;
    text-decoration: underline;

    &:hover {
        text-decoration: underline;
        color: ${color('text.main')};
    }

    &:focus {
        text-decoration: underline;
        color: ${color('text.main')};
    }
`;

export const Icon = styled(Plus)`
    margin-right: 8px;
`;
