import {
    HeaderIsLoadingContainer,
    IsLoadingCardContainer,
    IsLoadingItemContainer,
    StyledLoadingContainer,
    StyledLoadingShapeLarge,
    StyledLoadingShapeMedium,
    StyledLoadingShapeMediumLarge,
    StyledLoadingShapeSmall,
} from './styled';

const IsLoadingCard = () => {
    return (
        <IsLoadingCardContainer>
            <IsLoadingItemContainer>
                <StyledLoadingShapeSmall />
                <StyledLoadingShapeMediumLarge />
            </IsLoadingItemContainer>
            <IsLoadingItemContainer>
                <StyledLoadingShapeSmall />
                <StyledLoadingShapeMediumLarge />
            </IsLoadingItemContainer>
            <IsLoadingItemContainer>
                <StyledLoadingShapeSmall />
                <StyledLoadingShapeMediumLarge />
            </IsLoadingItemContainer>
        </IsLoadingCardContainer>
    );
};

const LoadingShapes = () => {
    return (
        <StyledLoadingContainer>
            <HeaderIsLoadingContainer>
                <StyledLoadingShapeLarge />
                <StyledLoadingShapeMedium />
            </HeaderIsLoadingContainer>
            <IsLoadingCard />
        </StyledLoadingContainer>
    );
};

export default LoadingShapes;
