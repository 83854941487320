import { useQuery as useReactQuery } from 'react-query';
import BackendClient from 'data/services/axios';

interface UseQueyI<Req, Res> {
    path: string;
    method?: 'get' | 'post' | 'put' | 'delete' | 'patch';
    body?: Req;
    key?: string;
    initialData?: Res;
    enabled?: boolean;
    refetchOnMount?: boolean;
    refetchOnWindowFocus?: boolean;
}

const useQuery = <Req, Res>(params: UseQueyI<Req, Res>) => {
    const {
        path,
        method = 'get',
        body,
        key = params.path,
        initialData,
        enabled = true,
        refetchOnMount = true,
        refetchOnWindowFocus = false,
    } = params;

    return useReactQuery(
        key,
        async () => {
            const response = await BackendClient[method]<Res>(path, body);
            return response.data;
        },
        {
            initialData,
            enabled,
            refetchOnMount,
            refetchOnWindowFocus,
        },
    );
};

export default useQuery;
