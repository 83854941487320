const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
});

const formatCurrency = (value: number) => formatter.format(value / 100);

export default formatCurrency;
