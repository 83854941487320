import { useDoc } from '@rainbow-modules/firebase-hooks';
import { Group } from '@transactional-message-tool/shared';

const useGroup = (appId?: string, groupId?: string) => {
    return useDoc<Group>({
        path: `/apps/${appId}/groups/${groupId}`,
        flat: true,
    });
};

export default useGroup;
