import { IconProps } from './types';

const Book = ({ title = 'edit', className }: IconProps) => {
    return (
        <svg className={className} width={24} height={24} viewBox="0 0 24 24" fill="none">
            <title>{title}</title>
            <path
                fill="currentColor"
                d="M1.5 3C1.5 2.72386 1.72386 2.5 2 2.5H8C9.19347 2.5 10.3381 2.97411 11.182 3.81802C12.0259 4.66193 12.5 5.80653 12.5 7V21C12.5 21.2761 12.2761 21.5 12 21.5C11.7239 21.5 11.5 21.2761 11.5 21C11.5 20.337 11.2366 19.7011 10.7678 19.2322C10.2989 18.7634 9.66304 18.5 9 18.5H2C1.72386 18.5 1.5 18.2761 1.5 18V3ZM11.5 18.5505V7C11.5 6.07174 11.1313 5.1815 10.4749 4.52513C9.8185 3.86875 8.92826 3.5 8 3.5H2.5V17.5H9C9.92826 17.5 10.8185 17.8687 11.4749 18.5251C11.4833 18.5335 11.4917 18.542 11.5 18.5505Z"
            />
            <path
                fill="currentColor"
                d="M12.818 3.81802C13.6619 2.97411 14.8065 2.5 16 2.5H22C22.2761 2.5 22.5 2.72386 22.5 3V18C22.5 18.2761 22.2761 18.5 22 18.5H15C14.337 18.5 13.7011 18.7634 13.2322 19.2322C12.7634 19.7011 12.5 20.337 12.5 21C12.5 21.2761 12.2761 21.5 12 21.5C11.7239 21.5 11.5 21.2761 11.5 21V7C11.5 5.80653 11.9741 4.66193 12.818 3.81802ZM12.5 18.5505C12.5083 18.542 12.5167 18.5335 12.5251 18.5251C13.1815 17.8687 14.0717 17.5 15 17.5H21.5V3.5H16C15.0717 3.5 14.1815 3.86875 13.5251 4.52513C12.8687 5.1815 12.5 6.07174 12.5 7V18.5505Z"
            />
        </svg>
    );
};

export default Book;
