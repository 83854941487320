import { useNavigate } from 'react-router-dom';
import {
    Content,
    GoBackLink,
    LayoutContainer,
    LoginLogo,
    PageContent,
    PrincipalHeader,
    StyledArrowLeftIcon,
    Subheading,
    Text,
    TopBar,
} from './styled';

const TermsAndPolicy = (props: { principalHeader?: string; children: React.ReactNode }) => {
    const { principalHeader, children } = props;
    const navigate = useNavigate();

    return (
        <LayoutContainer>
            <TopBar>
                <div>
                    <LoginLogo />
                </div>
            </TopBar>
            <PageContent>
                <Content>
                    <GoBackLink onClick={() => navigate('/')}>
                        <StyledArrowLeftIcon />
                        Go back
                    </GoBackLink>
                    <Subheading>Legal Terms</Subheading>
                    <PrincipalHeader>{principalHeader}</PrincipalHeader>
                    <Text>{children}</Text>
                </Content>
            </PageContent>
        </LayoutContainer>
    );
};

export default TermsAndPolicy;
