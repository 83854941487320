import { Column, RenderIf, Table, Button } from 'react-rainbow-components';
import { UniversalFormModal } from '@rainbow-modules/forms';
import { useConnectModal } from '@rainbow-modules/hooks';
import { useParams } from 'react-router-dom';
import useApiKeys from 'data/hooks/useApiKeys';
import useCreateApiKeys from 'data/hooks/useCreateApiKey';
import LoadingShapes from 'components/LoadingShapes';
import SectionHeader from '../sectionHeader';
import KeyColumn from './columns/keyColumn';
import CreatedAtColumn from './columns/createdAtColumn';
import CreatedByColumn from './columns/createdByColumn';
import ActionColumn from './columns/actionColumn';
import EmptyMessage from '../../../../components/EmptyMessage';
import Form from './form';
import { Container, StyledPlusIcon, StyledButton, StyledKeyImage } from './styled';

const ApiKeys = () => {
    const connectedCreateAPIKeyModalProps = useConnectModal('api-key');
    const { appId } = useParams();
    const { data, isLoading } = useApiKeys(appId as string);
    const { openCreateApiKey } = useCreateApiKeys(appId as string);
    const isEmpty = data?.length === 0;

    return (
        <Container>
            <RenderIf isTrue={isEmpty && !isLoading}>
                <EmptyMessage
                    className="rainbow-m-bottom_xx-large"
                    title="Create API Keys"
                    description="These keys will allow you to authenticate API requests."
                    icon={<StyledKeyImage />}
                    action={
                        <Button
                            variant="brand"
                            borderRadius="semi-rounded"
                            onClick={openCreateApiKey}
                        >
                            <StyledPlusIcon />
                            Create API Key
                        </Button>
                    }
                />
            </RenderIf>
            <RenderIf isTrue={!isEmpty && !isLoading}>
                <SectionHeader
                    title="Your Application API Keys"
                    description="Keys you have generated that can be used to access the reTalkable API."
                    actions={
                        <StyledButton
                            variant="outline-brand"
                            borderRadius="semi-rounded"
                            onClick={openCreateApiKey}
                        >
                            <StyledPlusIcon />
                            Create API Key
                        </StyledButton>
                    }
                />
                <Table data={data} keyField="id" variant="listview">
                    <Column
                        header="Key Name"
                        field="name"
                        headerAlignment="left"
                        component={KeyColumn}
                    />
                    <Column
                        header="Created At"
                        field="createdAt"
                        headerAlignment="left"
                        defaultWidth={180}
                        component={CreatedAtColumn}
                    />
                    <Column
                        header="Created By"
                        field="createdBy"
                        headerAlignment="left"
                        defaultWidth={210}
                        component={CreatedByColumn}
                    />
                    <Column component={ActionColumn} width={70} />
                </Table>
            </RenderIf>
            <RenderIf isTrue={isLoading}>
                <LoadingShapes />
            </RenderIf>
            <UniversalFormModal
                borderRadius="semi-rounded"
                {...connectedCreateAPIKeyModalProps}
                fields={Form}
            />
        </Container>
    );
};

export default ApiKeys;
