import { Picklist, Option } from 'react-rainbow-components';
import { useParams, useNavigate } from 'react-router-dom';
import useApps from 'data/hooks/useApps';
import useCreateApps from 'data/hooks/useCreateApps';
import PlusCircle from '../icons/plusCircle';
import Widget from '../icons/widget';
import Grid from '../icons/grid';

const AppSelector = () => {
    const { appId } = useParams();
    const navigate = useNavigate();
    const [apps, isLoading] = useApps();
    const openCreateApp = useCreateApps();
    if (isLoading) {
        return null;
    }

    const selectedApp = apps.find((app) => app.id === appId);
    const value = { name: selectedApp?.id, label: selectedApp?.name, icon: <Widget /> };
    if (selectedApp) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const onChange = (newValue) => {
            switch (newValue.name) {
                case 'apps':
                    navigate('/apps');
                    break;
                case 'new':
                    openCreateApp();
                    break;
                default:
                    navigate(`/app/${newValue.name}/groups`);
                    break;
            }
        };
        return (
            <Picklist variant="bare" borderRadius="semi-rounded" value={value} onChange={onChange}>
                <Option name="apps" label="All applications" icon={<Grid />} />
                <Option name="new" label="New application" icon={<PlusCircle />} />
                <Option name="header" label="Applications" variant="header" />
                {apps.map(({ id, name }) => {
                    return <Option name={id} label={name} key={id} icon={<Widget />} />;
                })}
            </Picklist>
        );
    }
    navigate('/apps');
    return null;
};

export default AppSelector;
