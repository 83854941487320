import BackendClient from 'data/services/axios';
import { AxiosResponse } from 'axios';

interface Props {
    enabled: boolean;
    appId: string;
    apikeyId: string;
}

const updateApiKey = async (props: Props) => {
    const { appId, apikeyId, enabled } = props;
    const response: AxiosResponse = await BackendClient.patch<Response>(
        `/apps/${appId}/apikeys/${apikeyId}`,
        {
            enabled,
        },
    );
    return response.data;
};

export default updateApiKey;
