import { IconProps } from './types';

const Message = ({ className, title }: IconProps) => {
    return (
        <svg className={className} width="20" height="21" viewBox="0 0 20 21">
            <title>{title}</title>
            <path
                fill="currentColor"
                d="M4.16667 3.88232C3.92355 3.88232 3.69039 3.9789 3.51849 4.15081C3.34658 4.32272 3.25 4.55588 3.25 4.79899V16.3217L5.303 14.2687C5.44366 14.128 5.63442 14.049 5.83333 14.049H15.8333C16.0764 14.049 16.3096 13.9524 16.4815 13.7805C16.6534 13.6086 16.75 13.3754 16.75 13.1323V4.79899C16.75 4.55588 16.6534 4.32272 16.4815 4.15081C16.3096 3.9789 16.0764 3.88232 15.8333 3.88232H4.16667ZM2.45783 3.09015C2.91104 2.63694 3.52573 2.38232 4.16667 2.38232H15.8333C16.4743 2.38232 17.089 2.63694 17.5422 3.09015C17.9954 3.54336 18.25 4.15805 18.25 4.79899V13.1323C18.25 13.7733 17.9954 14.388 17.5422 14.8412C17.089 15.2944 16.4743 15.549 15.8333 15.549H6.14399L3.03033 18.6627C2.81583 18.8772 2.49324 18.9413 2.21299 18.8252C1.93273 18.7091 1.75 18.4357 1.75 18.1323V4.79899C1.75 4.15805 2.00461 3.54336 2.45783 3.09015Z"
            />
        </svg>
    );
};

export default Message;
