import { DefaultTheme } from 'styled-components';

declare module 'styled-components' {
    interface DefaultTheme {
        rainbow: {
            palette: {
                [color: string]: {
                    [type: string]: string;
                };
            };
        };
    }
}

interface StyledThemeProps {
    theme: DefaultTheme;
}

export const color = (name: string): ((props: StyledThemeProps) => string) => {
    const [colorName, type] = name.split('.');
    return (props) => props.theme.rainbow.palette[colorName][type];
};
