import { useMutation } from 'react-query';
import { showAppNotification } from '@rainbow-modules/app';
import updateApiKey from 'data/services/apiKeys/update';

const useUpdateApiKey = () => {
    const { mutate } = useMutation(updateApiKey, {
        onError: () => {
            showAppNotification({
                title: 'Unfortunately, the API Key update process was unsuccessful.',
                description: 'Please try again or contact us for more assistance.',
                icon: 'error',
                timeout: 5000,
            });
        },
    });

    return {
        mutate,
    };
};

export default useUpdateApiKey;
