/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCollection } from '@rainbow-modules/firebase-hooks';
import { Group } from '@transactional-message-tool/shared';
import { where, query, orderBy } from 'firebase/firestore';

const useGroups = (appId: string) => {
    return useCollection<Group>({
        path: `/apps/${appId}/groups`,
        flat: true,
        // @ts-ignore
        query: (ref) => query(ref, where('removed', '==', false), orderBy('createdAt', 'asc')),
    });
};

export default useGroups;
