import { useEffect, useState } from 'react';
import BackendClient from 'data/services/axios';
import algoliasearch, { SearchClient } from 'algoliasearch/lite';
import { useParams } from 'react-router-dom';

const useAlgoliaClient = () => {
    const { appId, groupId } = useParams();
    const [client, setClient] = useState<SearchClient>();

    useEffect(() => {
        (async () => {
            const res = await BackendClient.get(
                `/contacts/search/auth?appId=${appId}&groupId=${groupId}`,
            );
            const { algoliaSecureKey } = res.data;
            setClient(
                algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID as string, algoliaSecureKey),
            );
        })();
    }, [appId, groupId]);

    return client;
};

export default useAlgoliaClient;
