import styled from 'styled-components';
import { color } from '../../../theme';

export const Container = styled.div`
    padding: 32px 32px 0 32px;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
`;

export const Header = styled.header`
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
`;

export const HeaderTitle = styled.h1`
    font-size: 24px;
    color: ${color('text.main')};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const SectionTitle = styled.div`
    font-size: 16px;
    color: ${color('text.main')};
    font-family: 'Lato Bold', Arial, Helvetica, sans-serif;
`;

export const SectionHeaderContainer = styled.header`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 12px;
`;

export const SectionHeaderLeftContent = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-right: 12px;
    flex-shrink: 1;
`;

export const SectionHeaderTitle = styled.h3`
    font-size: 18px;
    color: ${color('text.main')};
    font-family: 'Lato', Arial, Helvetica, sans-serif;
`;

export const SectionHeaderDescription = styled.p`
    font-size: 14px;
    color: ${color('text.header')};
    font-family: 'Lato', Arial, Helvetica, sans-serif;
`;
