import { IconProps } from './types';

const LogoLarge = ({ title, className }: IconProps) => {
    return (
        <svg className={className} width={212} height={37} viewBox="0 0 212 37" fill="currentColor">
            <title>{title}</title>
            <g className="logo">
                <path
                    d="M30.6153 36.4112C28.7664 33.4916 26.0925 32.1237 22.6491 32.1412C19.5773 32.1558 16.5055 32.1704 13.4366 32.0975C6.69033 31.937 1.17278 26.5471 1.00017 19.8388C0.92996 17.0854 0.850971 13.3754 1.39219 10.7038C2.5273 5.10673 7.84007 1.05549 13.5741 1.00008C17.035 0.967995 20.4959 0.973828 23.9568 1.00591C30.8582 1.07008 36.4752 6.60006 36.6098 13.5125C36.6566 15.8896 36.7473 19.1738 36.4167 21.5129C35.9223 24.9983 34.7082 26.5792 30.6709 30.2804C31.7241 31.5345 32.8592 32.7333 33.8071 34.0633C34.5531 35.157 34.2459 35.4954 33.1547 36.2304C32.8885 36.4141 32.3034 36.7495 32.0225 36.8633C31.0512 37.2862 30.6124 36.4112 30.6124 36.4112H30.6153ZM23.9334 27.9033C28.1433 27.8683 31.7153 25.0012 32.3209 20.8421C32.6544 18.5583 32.7276 15.2975 32.4731 13.008C31.9582 8.36464 28.6494 5.36923 23.9598 5.1884C20.4111 5.05131 16.8449 5.08048 13.2933 5.22631C9.18289 5.39839 5.85363 8.3588 5.30656 12.413C5.00523 14.6529 4.94379 17.8613 5.18954 20.1071C5.69273 24.7154 9.05709 27.7225 13.7028 27.9646C15.5488 28.0608 17.4036 27.9791 19.2526 27.9791L23.9305 27.9033H23.9334Z"
                    className="Vector"
                />
                <path
                    fill="currentColor"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 21.3519V13.8476H14.0786V14.4299C14.2076 14.3231 14.3421 14.2268 14.4824 14.1416C15.0027 13.824 15.5805 13.6669 16.2038 13.6669C16.6479 13.6669 17.0894 13.7352 17.5269 13.8695L17.8783 13.9773V17.1908H15.8405V15.6602C15.5952 15.676 15.3282 15.7812 15.033 16.0232C14.7275 16.2737 14.407 16.661 14.0786 17.208V21.3519H12ZM21.2407 21.3519V13.3037H18.5308V11.3125H26.1283V13.3037H23.4126V21.3519H21.2407Z"
                    className="rT (Stroke)"
                />
            </g>
            <g className="reTalkable">
                <path d="M52.599 29.5723V14.6428H55.085V17.904C55.8602 16.6744 56.7468 15.7566 57.7448 15.1507C58.7517 14.5358 59.8699 14.2284 61.0996 14.2284C61.9995 14.2284 62.8995 14.3665 63.7995 14.6428V20.0292H61.407V16.6209C60.9882 16.5496 60.6585 16.514 60.4179 16.514C59.4912 16.514 58.5824 16.8793 57.6913 17.61C56.8003 18.3406 55.9315 19.4366 55.085 20.8979V29.5723H52.599Z" />
                <path d="M80.1858 28.5832C78.1988 29.5277 76.3187 30 74.5455 30C73.2 30 71.9971 29.6659 70.9368 28.9976C69.8764 28.3293 69.0255 27.3759 68.3839 26.1373C67.7513 24.8898 67.4349 23.5577 67.4349 22.1409C67.4349 20.7776 67.7379 19.4678 68.3438 18.2114C68.9497 16.955 69.7962 15.9793 70.8833 15.2843C71.9704 14.5804 73.1822 14.2284 74.5188 14.2284C76.292 14.2284 77.7132 14.8611 78.7824 16.1264C79.8606 17.3917 80.3997 19.3431 80.3997 21.9806V22.5152H70.1081C70.1081 23.5488 70.3219 24.4933 70.7496 25.3487C71.1863 26.2041 71.7565 26.8457 72.4605 27.2734C73.1644 27.7011 73.9663 27.9149 74.8663 27.9149C76.4702 27.9149 78.2434 27.3803 80.1858 26.3111V28.5832ZM70.2551 20.6574H77.6998V20.2965C77.6998 19.0757 77.4013 18.1045 76.8043 17.3827C76.2073 16.661 75.4098 16.3001 74.4118 16.3001C73.405 16.3001 72.5317 16.6699 71.7922 17.4095C71.0615 18.1401 70.5492 19.2228 70.2551 20.6574Z" />
                <path d="M88.9939 29.5723V11.1142H82.7788V8.82868H97.9222V11.1142H91.6937V29.5723H88.9939Z" />
                <path d="M110.459 26.2175C109.702 27.465 108.789 28.405 107.719 29.0377C106.659 29.6703 105.683 29.9866 104.792 29.9866C103.545 29.9866 102.422 29.4475 101.424 28.3694C100.435 27.2912 99.9404 25.6561 99.9404 23.4642C99.9404 21.7355 100.27 20.194 100.929 18.8396C101.589 17.4763 102.471 16.4026 103.576 15.6185C104.681 14.8254 106.049 14.4289 107.679 14.4289C108.151 14.4289 108.864 14.4779 109.818 14.5759C109.96 14.5938 110.174 14.616 110.459 14.6428H112.959V25.1883C112.959 26.9883 113.146 28.4496 113.52 29.5723H110.927C110.731 28.7525 110.575 27.6343 110.459 26.2175ZM110.459 23.2637V16.8615C109.577 16.6298 108.757 16.514 108 16.514C106.467 16.514 105.189 17.1065 104.164 18.2916C103.148 19.4767 102.64 21.0895 102.64 23.13C102.64 24.6092 102.917 25.7274 103.469 26.4848C104.03 27.2333 104.667 27.6075 105.38 27.6075C106.155 27.6075 107.011 27.2155 107.946 26.4313C108.882 25.6472 109.72 24.5913 110.459 23.2637Z" />
                <path d="M121.098 29.5723V10.0851H116.634V8H123.584V27.5006H128.249V29.5723H121.098Z" />
                <path d="M134.197 29.5723V8H136.683V21.5529L143.86 14.6428H147.175L140.011 21.5529L147.362 29.5723H143.94L136.683 21.5529V29.5723H134.197Z" />
                <path d="M159.779 26.2175C159.021 27.465 158.108 28.405 157.039 29.0377C155.978 29.6703 155.003 29.9866 154.112 29.9866C152.864 29.9866 151.742 29.4475 150.744 28.3694C149.754 27.2912 149.26 25.6561 149.26 23.4642C149.26 21.7355 149.59 20.194 150.249 18.8396C150.908 17.4763 151.791 16.4026 152.895 15.6185C154 14.8254 155.368 14.4289 156.999 14.4289C157.471 14.4289 158.184 14.4779 159.137 14.5759C159.28 14.5938 159.494 14.616 159.779 14.6428H162.278V25.1883C162.278 26.9883 162.465 28.4496 162.84 29.5723H160.247C160.051 28.7525 159.895 27.6343 159.779 26.2175ZM159.779 23.2637V16.8615C158.897 16.6298 158.077 16.514 157.32 16.514C155.787 16.514 154.508 17.1065 153.484 18.2916C152.468 19.4767 151.96 21.0895 151.96 23.13C151.96 24.6092 152.236 25.7274 152.789 26.4848C153.35 27.2333 153.987 27.6075 154.7 27.6075C155.475 27.6075 156.33 27.2155 157.266 26.4313C158.202 25.6472 159.039 24.5913 159.779 23.2637Z" />
                <path d="M168.707 17.9976C169.473 16.7501 170.387 15.81 171.447 15.1774C172.507 14.5448 173.483 14.2284 174.374 14.2284C175.622 14.2284 176.744 14.7675 177.742 15.8457C178.74 16.9239 179.239 18.5589 179.239 20.7509C179.239 22.4795 178.905 24.0255 178.237 25.3888C177.578 26.7432 176.695 27.8169 175.59 28.61C174.486 29.3941 173.118 29.7861 171.487 29.7861C171.024 29.7861 170.311 29.7371 169.349 29.6391C169.206 29.6213 168.992 29.599 168.707 29.5723H166.221V8H168.707V17.9976ZM168.707 20.9514V27.3536C169.607 27.5853 170.436 27.7011 171.193 27.7011C172.699 27.7011 173.964 27.113 174.989 25.9368C176.023 24.7517 176.539 23.1345 176.539 21.0851C176.539 19.597 176.259 18.4787 175.697 17.7303C175.136 16.9818 174.499 16.6075 173.786 16.6075C173.011 16.6075 172.155 16.9996 171.22 17.7837C170.293 18.5678 169.456 19.6237 168.707 20.9514Z" />
                <path d="M186.858 29.5723V10.0851H182.394V8H189.344V27.5006H194.008V29.5723H186.858Z" />
                <path d="M211.705 28.5832C209.718 29.5277 207.838 30 206.064 30C204.719 30 203.516 29.6659 202.456 28.9976C201.395 28.3293 200.544 27.3759 199.903 26.1373C199.27 24.8898 198.954 23.5577 198.954 22.1409C198.954 20.7776 199.257 19.4678 199.863 18.2114C200.469 16.955 201.315 15.9793 202.402 15.2843C203.489 14.5804 204.701 14.2284 206.038 14.2284C207.811 14.2284 209.232 14.8611 210.301 16.1264C211.379 17.3917 211.919 19.3431 211.919 21.9806V22.5152H201.627C201.627 23.5488 201.841 24.4933 202.268 25.3487C202.705 26.2041 203.275 26.8457 203.979 27.2734C204.683 27.7011 205.485 27.9149 206.385 27.9149C207.989 27.9149 209.762 27.3803 211.705 26.3111V28.5832ZM201.774 20.6574H209.219V20.2965C209.219 19.0757 208.92 18.1045 208.323 17.3827C207.726 16.661 206.929 16.3001 205.931 16.3001C204.924 16.3001 204.051 16.6699 203.311 17.4095C202.58 18.1401 202.068 19.2228 201.774 20.6574Z" />
            </g>
        </svg>
    );
};

export default LogoLarge;
