import { CheckboxToggle, RenderIf } from 'react-rainbow-components';
import { ApiKey } from '@transactional-message-tool/shared';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import useUpdateApiKeys from 'data/hooks/useUpdateApiKey';
import {
    KeyValue,
    ToggleLabel,
    NameColumnValue,
    StyledColumnNameRow,
    StyledColumnWrapper,
    StyledKeyValueContainer,
    StyledCopyToClipboardButton,
    StyledKeyValueContent,
    StyledWarningContainer,
    StyledWarningIcon,
    StyledWarningText,
} from '../styled';

interface Row extends ApiKey {
    new?: boolean;
}

interface Props {
    value?: string;
    row?: Row;
}

const getNewData = (oldData: ApiKey[] | undefined, rowId: string) => {
    return oldData?.map((item) => {
        if (item.id === rowId) {
            // eslint-disable-next-line no-param-reassign
            item.enabled = !item.enabled;
        }
        return item;
    });
};

const KeyColumn = ({ value, row }: Props) => {
    const queryClient = useQueryClient();
    const { appId } = useParams() as { appId: string };
    const apikeyId = row?.id as string;
    const { mutate } = useUpdateApiKeys();
    const toggle = row?.enabled ? 'ON' : 'OFF';

    const handleChange = () => {
        mutate({ enabled: !row?.enabled, appId, apikeyId });

        queryClient.setQueryData<ApiKey[]>(
            `/apps/${appId}/apikeys`,
            (oldData) => getNewData(oldData, apikeyId) || [],
        );
    };

    return (
        <StyledColumnWrapper className="rainbow-p-left_medium" new={row?.new}>
            <StyledColumnNameRow>
                <NameColumnValue new={row?.new}>{value}</NameColumnValue>
                <RenderIf isTrue={!row?.new}>
                    <CheckboxToggle
                        id="checkbox-toggle-component-1"
                        label={<ToggleLabel>Key {toggle}</ToggleLabel>}
                        labelAlignment="left"
                        className="rainbow-m-right_x-small"
                        value={row?.enabled}
                        onChange={handleChange}
                    />
                </RenderIf>
            </StyledColumnNameRow>
            <RenderIf isTrue={row?.new}>
                <StyledKeyValueContainer>
                    <StyledKeyValueContent>
                        <KeyValue>{row?.value}</KeyValue>
                        <StyledCopyToClipboardButton value={row?.value} />
                    </StyledKeyValueContent>
                    <StyledWarningContainer>
                        <StyledWarningIcon />
                        <StyledWarningText>
                            Copy your API key now, and treat it like a password. For your security,
                            we won‘t show it again!
                        </StyledWarningText>
                    </StyledWarningContainer>
                </StyledKeyValueContainer>
            </RenderIf>
        </StyledColumnWrapper>
    );
};

export default KeyColumn;
