import BackendClient from 'data/services/axios';
import { AxiosResponse } from 'axios';
import { ApiKeyCreate } from '@transactional-message-tool/shared';

interface Props {
    values: ApiKeyCreate;
    appId: string;
}

const createApiKey = async (props: Props) => {
    const { name } = props.values;
    const { appId } = props;
    const response: AxiosResponse = await BackendClient.post<Response>(`/apps/${appId}/apikeys`, {
        name,
    });
    return response.data;
};

export default createApiKey;
