import {
    SectionHeaderContainer,
    SectionHeaderLeftContent,
    SectionHeaderDescription,
    SectionHeaderTitle,
} from './styled';

interface SectionHeaderProps {
    className?: string;
    title?: React.ReactNode | string;
    description?: React.ReactNode | string;
    actions?: React.ReactNode;
}

const SectionHeader = (props: SectionHeaderProps) => {
    const { title, description, actions, className } = props;

    return (
        <SectionHeaderContainer className={className}>
            <SectionHeaderLeftContent>
                <SectionHeaderTitle>{title}</SectionHeaderTitle>
                <SectionHeaderDescription>{description}</SectionHeaderDescription>
            </SectionHeaderLeftContent>
            {actions}
        </SectionHeaderContainer>
    );
};

export default SectionHeader;
