import { Book, Settings, Widget } from 'components/icons';
import { useNavigate } from 'react-router-dom';
import { Container, Option, Icon } from './styled';

const isSelected = (page: string) => {
    return window.location.pathname.includes(page);
};

const LeftMenu = () => {
    const navigate = useNavigate();
    return (
        <Container>
            <Option selected={isSelected('groups')} onClick={() => navigate('groups')}>
                <Icon />
                <span>Groups</span>
            </Option>
            <Option selected={isSelected('widget')} onClick={() => navigate('widget')}>
                <Icon as={Widget} />
                <span>Widget</span>
            </Option>
            <Option selected={isSelected('settings')} onClick={() => navigate('settings')}>
                <Icon as={Settings} />
                <span>Settings</span>
            </Option>
            <Option
                as="a"
                href="https://nexxtway.gitbook.io/retalkable/"
                target="_blank"
                rel="noopener"
            >
                <Icon as={Book} />
                <span>Documentation</span>
            </Option>
        </Container>
    );
};

export default LeftMenu;
