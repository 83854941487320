import styled from 'styled-components';
import { Plus, Trash } from '@rainbow-modules/icons';
import { Avatar, FileSelector } from 'react-rainbow-components';
import { Link } from 'react-router-dom';
import DateTimeColumn from 'components/DateTimeColumn';
import { color } from 'theme';

export const Container = styled.div`
    background-color: ${(props) => props.theme.rainbow.palette.background.main};
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

export const TitleContainer = styled.div`
    margin: 20px 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Title = styled.h1`
    font-family: Lato Bold, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    color: ${color('text.main')};
`;

export const LeftBar = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    gap: 16px;
    width: 280px;
    height: 100vh;
    background: #f5f5fa;
    flex: none;
    order: 0;
    flex-grow: 0;
`;

export const RightContent = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: calc(100% - 280px);
    height: 100vh;
    background: white;
`;

export const PageContent = styled.main`
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: scroll;
`;

export const StyledPlusIcon = styled(Plus)`
    margin-right: 8px;
`;

export const StyledPlusIconLogo = styled(Plus)`
    color: ${color('background')};
`;

export const StyledFileSelector = styled(FileSelector)`
    margin-top: 20px;
    width: 12rem;
`;

export const StyledLink = styled(Link)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-width: 50%;

    :hover {
        color: ${(props) => props.theme.rainbow.palette.text.main};
        background-color: ${(props) => props.theme.rainbow.palette.background.highlight};
        border-radius: 8px;
        padding-right: 20px;
    }
`;

export const StyledApplicationAvatar = styled(Avatar)`
    border-radius: 10px;
    margin-right: 16px;
    flex-shrink: 0;
`;

export const StyledApplicationNameContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    min-width: 50%;
`;

export const StyledApplicationName = styled.h3`
    font-size: 16px;
    line-height: 20px;
    color: ${color('text.main')};
    justify-content: flex-start;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
`;

export const StyledApplicationId = styled.p`
    font-size: 12px;
    line-height: 16px;
    color: ${color('text.header')};
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    min-width: 50%;
`;

export const StyledActions = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    padding: 10px 24px;
`;

export const StyledColumnWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 10px 12px;
`;

export const TrashIconDelete = styled(Trash)`
    width: 60px;
    height: 60px;
    margin: 0 12px 0 0;
    color: ${color('text.main')};
`;

export const StyledDateTimeColumn = styled(DateTimeColumn)`
    margin: 10px 12px;
`;

export const HeaderTitle = styled.h1`
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: ${color('text.main')};
    font-family: Lato, Arial, Helvetica, sans-serif;
    margin-bottom: 15px;
`;

export const StyledNoMessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 5rem 0;
`;

export const StyledMessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 3rem 0;
`;

export const StyledNoAppsMessage = styled.h3`
    font-size: 22px;
    font-weight: 700;
    font-family: Lato, Helvetica, sans-serif;
    color: ${color('text.main')};
`;

export const StyledNoAppsMessageDescription = styled.span`
    display: block;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    font-family: Lato, Helvetica, sans-serif;
    color: ${color('text.header')};
    max-width: 600px;
`;
