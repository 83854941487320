import BackendClient from 'data/services/axios';
import { useMutationFlow } from '@rainbow-modules/hooks';
import { MutationOptions } from '@rainbow-modules/hooks/lib/useMutationFlow';

type Methods = 'get' | 'post' | 'patch' | 'put' | 'delete';

interface Params<B> {
    path: string;
    method: Methods;
    body?: B | Record<string, unknown>;
}

const makeRequest = async <B, R>({ path, method, body }: Params<B>) => {
    const response = await BackendClient[method]<R>(path, body);
    return response.data;
};

interface Props extends Omit<MutationOptions, 'mutation'> {
    method: Methods;
}

interface MutateArgs<B> {
    body?: B | Record<string, unknown>;
    path: string;
}

const useMutation = <B, R>(props: Props): ((mutateArgs: MutateArgs<B>) => Promise<R>) => {
    const { method, ...rest } = props;
    const { mutate } = useMutationFlow({
        mutation: ({ body, path }) => makeRequest<B, R>({ path, method, body }),
        ...rest,
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return mutate;
};

export default useMutation;
