import { Header, HeaderTitle, PreviewPageContainer, Content, LargeRow, SmallRow } from './styled';

const Preview = () => {
    return (
        <Content>
            <Header>
                <HeaderTitle>Preview</HeaderTitle>
            </Header>
            <PreviewPageContainer>
                <div className="rainbow-m-bottom_x-large rainbow-p-bottom_x-large">
                    <LargeRow />
                    <LargeRow />
                    <LargeRow />
                    <LargeRow />
                    <LargeRow />
                    <SmallRow />
                </div>
                <div>
                    <LargeRow />
                    <LargeRow />
                    <LargeRow />
                    <SmallRow />
                </div>
            </PreviewPageContainer>
        </Content>
    );
};

export default Preview;
