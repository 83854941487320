/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Input, RenderIf } from 'react-rainbow-components';
import { StateErrorMessage } from 'components/SelectPhoneNumber/styled';
import { Container, ItemContainer, ItemDescription, ItemName, LeftContent } from './styled';
import { TwilioResponse } from '../../pages/app/Apps/types';

interface AvailablePhoneNumbersListProps {
    availableNumbers: TwilioResponse[];
    onChange: (number: TwilioResponse) => void;
    value: TwilioResponse;
    showError: boolean;
}

const AvailablePhoneNumbersList = (props: AvailablePhoneNumbersListProps) => {
    const { availableNumbers, onChange, value, showError } = props;

    const isSelected = (number: TwilioResponse) => {
        return value?.friendlyName === number.friendlyName;
    };
    const onClick = (number: TwilioResponse) => {
        onChange(number);
    };
    return (
        <>
            <Container>
                {availableNumbers.map((number) => (
                    <ItemContainer
                        key={number.phoneNumber}
                        isSelected={isSelected(number)}
                        onClick={() => onClick(number)}
                    >
                        <LeftContent>
                            <ItemName isSelected={isSelected(number)}>
                                {number.friendlyName}
                            </ItemName>
                            <ItemDescription isSelected={isSelected(number)}>
                                {number.locality} {number.region}
                            </ItemDescription>
                        </LeftContent>
                        <Input
                            type="radio"
                            name="numbers"
                            value={number.friendlyName}
                            onChange={() => onClick(number)}
                            checked={isSelected(number)}
                        />
                    </ItemContainer>
                ))}
            </Container>
            <RenderIf isTrue={showError}>
                <StateErrorMessage>
                    You must select one of the available phone numbers for your group
                </StateErrorMessage>
            </RenderIf>
        </>
    );
};

export default AvailablePhoneNumbersList;
