import { IconProps } from './types';

const PhoneSquare = ({ className }: IconProps) => {
    return (
        <svg className={className} width="55" height="54" viewBox="0 0 55 54" fill="none">
            <path
                opacity="0.4"
                d="M39.058 0.333008H15.9407C6.90332 0.333008 0.833984 6.67434 0.833984 16.1117V37.8903C0.833984 47.325 6.90332 53.6663 15.9407 53.6663H39.0553C48.0927 53.6663 54.1673 47.325 54.1673 37.8903V16.1117C54.1673 6.67434 48.0953 0.333008 39.058 0.333008Z"
                fill="#CCC2DC"
            />
            <path
                d="M39.6657 33.2398V36.7398C39.667 37.0647 39.6005 37.3863 39.4703 37.684C39.3401 37.9817 39.1492 38.249 38.9098 38.4686C38.6704 38.6883 38.3877 38.8555 38.0799 38.9596C37.7721 39.0637 37.446 39.1024 37.1224 39.0731C33.5323 38.683 30.0839 37.4563 27.054 35.4915C24.2352 33.7002 21.8453 31.3103 20.054 28.4915C18.0823 25.4479 16.8553 21.9826 16.4724 18.3765C16.4432 18.0538 16.4815 17.7287 16.5849 17.4217C16.6883 17.1147 16.8545 16.8326 17.0729 16.5933C17.2913 16.3541 17.5571 16.1629 17.8534 16.0321C18.1498 15.9012 18.4701 15.8334 18.794 15.8331H22.294C22.8602 15.8275 23.4091 16.028 23.8384 16.3972C24.2677 16.7664 24.5481 17.2791 24.6274 17.8398C24.7751 18.9599 25.0491 20.0596 25.444 21.1181C25.601 21.5357 25.635 21.9895 25.5419 22.4258C25.4489 22.8621 25.2327 23.2626 24.919 23.5798L23.4374 25.0615C25.0982 27.9823 27.5166 30.4006 30.4374 32.0615L31.919 30.5798C32.2362 30.2661 32.6367 30.0499 33.073 29.9569C33.5093 29.8638 33.9631 29.8978 34.3807 30.0548C35.4392 30.4498 36.5389 30.7237 37.659 30.8715C38.2258 30.9514 38.7433 31.2369 39.1133 31.6735C39.4833 32.1102 39.6799 32.6676 39.6657 33.2398Z"
                fill="#6750A4"
            />
        </svg>
    );
};

export default PhoneSquare;
