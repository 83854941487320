import styled, { css } from 'styled-components';
import { TOP_BAR_HEIGHT } from '../../../constants';
import { Users } from '../../../components/icons';
import { color } from '../../../theme';

export const Container = styled.ul`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

interface OptionProps {
    selected?: boolean;
}
export const Option = styled.li<OptionProps>`
    display: flex;
    align-items: center;
    padding: 12px 24px;
    width: 100%;
    background: transparent;
    border-radius: 10px;
    flex: none;
    order: 1;
    color: ${color('text.label')};
    cursor: pointer;

    &:hover,
    :visited,
    :focus {
        background-color: ${color('brand.light')};
        text-decoration: none;
        color: ${color('text.label')};
    }

    ${(props) =>
        props.selected &&
        css`
            background-color: ${color('brand.main')};
            color: white;
            cursor: default;

            &:hover {
                background-color: ${color('brand.main')};
                color: white;
            }
        `}
`;

export const Icon = styled(Users)`
    display: 30px;
    height: 30px;
    margin-right: 16px;
`;

export const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    height: ${TOP_BAR_HEIGHT}px;
    border-bottom: 1px solid ${color('text.disabled')};
    margin-bottom: 1rem;
`;

export const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;
