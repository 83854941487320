import { RenderIf } from 'react-rainbow-components';
import { Container, DescriptionContainer, TitleContainer } from './styled';

interface Props {
    title: string;
    description?: string;
}

const ModalTitle = (props: Props) => {
    const { title, description } = props;
    return (
        <Container>
            <TitleContainer>{title}</TitleContainer>
            <RenderIf isTrue={description}>
                <DescriptionContainer>{description}</DescriptionContainer>
            </RenderIf>
        </Container>
    );
};

export default ModalTitle;
