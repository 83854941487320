import { Route, Routes, Navigate, Outlet } from 'react-router-dom';
import { RainbowFirebaseApp } from '@rainbow-modules/app';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Spinner } from 'react-rainbow-components';
import { Login, PrivacyPolicy, TermsAndConditions } from 'pages';
import MainLayout from 'layout/MainLayout';
import { WhenAuthenticated, WhenNoAuthenticated } from '@rainbow-modules/auth';
import { UniversalFormModal } from '@rainbow-modules/forms';
import AppsFormFields from 'pages/app/Apps/appsFormFields';
import { useConnectModal } from '@rainbow-modules/hooks';
import Identity from 'pages/app/Widget/Identity';
import Preview from 'pages/app/Widget/preview';
import { BRAND, SUCCESS, ERROR, WARNING, APP_MODAL, APP_MODAL_EDIT } from './constants';
import { app as firebaseApp } from './firebase';
import Apps from './pages/app/Apps';
import Groups from './pages/app/Groups';
import Widget from './pages/app/Widget';
import Settings from './pages/app/Settings';
import GeneralDetails from './pages/app/Settings/GeneralDetails';
// import ManageUsers from 'pages/app/Settings/ManageUsers';
import ApiKeys from './pages/app/Settings/ApiKeys';
import Contacts from './pages/app/Groups/Contacts';

const theme = {
    rainbow: {
        palette: {
            brand: BRAND,
            success: SUCCESS,
            warning: WARNING,
            error: ERROR,
        },
    },
};

const queryClient = new QueryClient();

const AppsEditFormFields = () => {
    return <AppsFormFields action="edit" />;
};

const App = () => {
    const connectedCreateAppsModalProps = useConnectModal(APP_MODAL);
    const connectedEditAppsModalProps = useConnectModal(APP_MODAL_EDIT);

    return (
        <RainbowFirebaseApp
            app={firebaseApp}
            theme={theme}
            spinner={<Spinner type="arc" variant="brand" size="x-large" />}
        >
            <QueryClientProvider client={queryClient}>
                <Routes>
                    <Route
                        path="/login"
                        element={
                            <WhenNoAuthenticated redirect="/apps">
                                <Login />
                            </WhenNoAuthenticated>
                        }
                    />
                    <Route
                        path="/"
                        element={
                            <WhenAuthenticated path="/" redirect="/login">
                                <Outlet />
                            </WhenAuthenticated>
                        }
                    >
                        <Route path="/" element={<Apps />} />
                        <Route path="app/:appId" element={<MainLayout />}>
                            <Route path="" element={<Navigate to="groups" replace />} />
                            <Route path="groups" element={<Groups />}>
                                <Route path=":groupId" element={<Contacts />} />
                            </Route>
                            <Route path="widget" element={<Widget />}>
                                <Route path="" element={<Navigate to="preview" replace />} />
                                <Route path="preview" element={<Preview />} />
                                <Route path="identity" element={<Identity />} />
                            </Route>
                            <Route path="settings" element={<Settings />}>
                                <Route path="" element={<Navigate to="details" replace />} />
                                <Route path="details" element={<GeneralDetails />} />
                                {/* <Route path="users" element={<ManageUsers />} /> */}
                                <Route path="apikeys" element={<ApiKeys />} />
                            </Route>
                        </Route>
                    </Route>
                    <Route path="terms-of-service" element={<TermsAndConditions />} />
                    <Route path="privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
                <UniversalFormModal
                    borderRadius="semi-rounded"
                    {...connectedCreateAppsModalProps}
                    fields={AppsFormFields}
                />
                <UniversalFormModal
                    borderRadius="semi-rounded"
                    {...connectedEditAppsModalProps}
                    fields={AppsEditFormFields}
                />
            </QueryClientProvider>
        </RainbowFirebaseApp>
    );
};

export default App;
