/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Input, RenderIf } from 'react-rainbow-components';
import { isRequired, Field, isUrl } from '@rainbow-modules/forms';
import { HeaderTitle } from './styled';
import SelectPhoneNumberForm from '../../../components/SelectPhoneNumber';

const InputField = (props: Record<string, unknown>) => {
    return <Input borderRadius="semi-rounded" labelAlignment="left" {...props} />;
};

interface AppsFormProps {
    action?: 'create' | 'edit';
}

const AppsFormFields = (props: AppsFormProps) => {
    const { action } = props;

    return (
        <div className="rainbow-m-horizontal_large">
            <HeaderTitle>Application Information</HeaderTitle>
            <Field
                name="name"
                component={InputField}
                label="Application Name"
                // @ts-ignore
                validate={isRequired()}
                required
                placeholder="Enter a name for your application"
                className="rainbow-m-bottom_large"
            />
            <Field
                name="authUrl"
                component={InputField}
                label="Auth URL"
                validate={isUrl()}
                placeholder="Enter an authentication URL for your application"
                className="rainbow-m-bottom_large"
            />
            <RenderIf isTrue={action !== 'edit'}>
                <SelectPhoneNumberForm />
            </RenderIf>
        </div>
    );
};

export default AppsFormFields;
