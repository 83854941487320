import { ApiKey } from '@transactional-message-tool/shared';
import formatter from 'data/services/date/formatter';
import { StyledColumnWrapper } from '../styled';

interface Row extends ApiKey {
    new?: boolean;
}

interface Props {
    value?: string;
    row?: Row;
}

const CreatedAtColumn = ({ value, row }: Props) => {
    const formattedDate = formatter.format(Number(value));
    return <StyledColumnWrapper new={row?.new}>{formattedDate}</StyledColumnWrapper>;
};

export default CreatedAtColumn;
