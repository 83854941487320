/* eslint-disable react/jsx-no-useless-fragment */
import { useCollection } from '@rainbow-modules/firebase-hooks';
import { Contact as ContactI } from '@transactional-message-tool/shared';
import { useParams } from 'react-router-dom';
import DateTimeColumn from 'components/DateTimeColumn';
import { limit, query } from 'firebase/firestore';
import Contact from './contact';
import NoContactsMessage from './noContactsMessage';

const ContactsFromFirestore = () => {
    const { appId, groupId } = useParams();
    const [data, isLoading] = useCollection<{ data: ContactI }>({
        path: `/apps/${appId}/groups/${groupId}/contacts`,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        query: (ref) => query(ref, limit(50)),
    });
    const contacts = data.map((item) => {
        const { firstName, lastName, phoneNumber, createdAt, phoneNumberParts } = item.data;
        return (
            <Contact
                id={item.id}
                firstName={firstName}
                lastName={lastName}
                phoneNumber={phoneNumber}
                key={item.id}
                createdAt={<DateTimeColumn value={createdAt} />}
                phoneNumberParts={phoneNumberParts}
            />
        );
    });
    if (!isLoading && contacts.length === 0) {
        return <NoContactsMessage />;
    }
    return <>{contacts}</>;
};

export default ContactsFromFirestore;
