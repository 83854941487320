import { App } from '@transactional-message-tool/shared';
import { useDoc } from '@rainbow-modules/firebase-hooks';

const useApp = (appId?: string) => {
    return useDoc<App>({
        path: `/apps/${appId}`,
        flat: true,
    });
};

export default useApp;
