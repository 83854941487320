import { isRequired } from '@rainbow-modules/forms';
import SelectPhoneNumberForm from 'components/SelectPhoneNumber';
import { Field } from 'react-final-form';
import { Input, RenderIf, Textarea } from 'react-rainbow-components';

interface GroupFormProps {
    action?: 'create' | 'edit';
}

const GroupForm = (props: GroupFormProps) => {
    const { action } = props;
    return (
        <div className="rainbow-m-horizontal_large">
            <Field
                name="name"
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={Input}
                validate={isRequired()}
                label="Group Name"
                required
                placeholder="Enter a name for your group"
                className="rainbow-m-bottom_large"
                borderRadius="semi-rounded"
                labelAlignment="left"
            />
            <Field
                name="externalId"
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={Input}
                validate={isRequired()}
                label="External ID"
                required
                placeholder="Enter a external ID for your group"
                className="rainbow-m-bottom_large"
                borderRadius="semi-rounded"
                labelAlignment="left"
            />
            <Field
                name="description"
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={Textarea}
                label="Group Description"
                placeholder="Enter a description for your group"
                className="rainbow-m-bottom_large"
                borderRadius="semi-rounded"
                labelAlignment="left"
            />
            <RenderIf isTrue={action !== 'edit'}>
                <SelectPhoneNumberForm />
            </RenderIf>
        </div>
    );
};

export default GroupForm;
