import { Outlet } from 'react-router-dom';
import LeftMenu from './LeftMenu';
import Topbar from './Topbar';
import {
    LayoutContainer,
    PageContent,
    LeftBar,
    RightContent,
    LogoContainer,
    LogoLargeIcon,
} from './styled';

const MainLayout = () => {
    return (
        <LayoutContainer>
            <LeftBar>
                <LogoContainer>
                    <LogoLargeIcon />
                </LogoContainer>
                <LeftMenu />
            </LeftBar>
            <RightContent>
                <Topbar isInner />
                <PageContent>
                    <Outlet />
                </PageContent>
            </RightContent>
        </LayoutContainer>
    );
};

export default MainLayout;
