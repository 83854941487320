import { Tab, Tabset } from 'react-rainbow-components';
import { Outlet, useNavigate, useMatch } from 'react-router-dom';
import { Container, Header, HeaderTitle } from './styled';

const Settings = () => {
    const navigate = useNavigate();
    const match = useMatch(`/app/:appId/settings/:tabName`);

    return (
        <Container>
            <Header>
                <HeaderTitle>Settings</HeaderTitle>
            </Header>
            <Tabset
                variant="line"
                onSelect={(event, name) => {
                    navigate(name);
                    event.preventDefault();
                }}
                activeTabName={match?.params?.tabName}
            >
                <Tab name="details" label="General Details" />
                {/* <Tab name="users" label="Manage Users" /> */}
                <Tab name="apikeys" label="API Keys" />
            </Tabset>
            <Outlet />
        </Container>
    );
};

export default Settings;
