import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

interface PhoneNumberValueI {
    isoCode: string;
    phone: string;
    countryCode: string;
}

const isPhoneNumber = () => {
    return (value: PhoneNumberValueI) => {
        const { isoCode, countryCode, phone } = value;
        const errorMsg = `This is not a valid phone number for ${isoCode.toLocaleUpperCase()}`;
        let number;
        try {
            number = phoneUtil.parseAndKeepRawInput(
                `${countryCode}${phone}`,
                isoCode.toUpperCase(),
            );
            return phoneUtil.isValidNumber(number) ? undefined : errorMsg;
        } catch {
            return errorMsg;
        }
    };
};

export default isPhoneNumber;
