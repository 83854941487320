import { IconProps } from './types';

const Widget = ({ title = 'Widget', className }: IconProps) => {
    return (
        <svg className={className} width={24} height={24} viewBox="0 0 24 24" fill="none">
            <title>{title}</title>
            <path
                fill="currentColor"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.23223 3.23223C3.70107 2.76339 4.33696 2.5 5 2.5H8C8.27614 2.5 8.5 2.72386 8.5 3C8.5 3.27614 8.27614 3.5 8 3.5H5C4.60218 3.5 4.22064 3.65804 3.93934 3.93934C3.65804 4.22064 3.5 4.60218 3.5 5V8C3.5 8.27614 3.27614 8.5 3 8.5C2.72386 8.5 2.5 8.27614 2.5 8V5C2.5 4.33696 2.76339 3.70107 3.23223 3.23223ZM15.5 3C15.5 2.72386 15.7239 2.5 16 2.5H19C19.663 2.5 20.2989 2.76339 20.7678 3.23223C21.2366 3.70107 21.5 4.33696 21.5 5V8C21.5 8.27614 21.2761 8.5 21 8.5C20.7239 8.5 20.5 8.27614 20.5 8V5C20.5 4.60218 20.342 4.22064 20.0607 3.93934C19.7794 3.65804 19.3978 3.5 19 3.5H16C15.7239 3.5 15.5 3.27614 15.5 3ZM3 15.5C3.27614 15.5 3.5 15.7239 3.5 16V19C3.5 19.3978 3.65804 19.7794 3.93934 20.0607C4.22064 20.342 4.60218 20.5 5 20.5H8C8.27614 20.5 8.5 20.7239 8.5 21C8.5 21.2761 8.27614 21.5 8 21.5H5C4.33696 21.5 3.70107 21.2366 3.23223 20.7678C2.76339 20.2989 2.5 19.663 2.5 19V16C2.5 15.7239 2.72386 15.5 3 15.5ZM21 15.5C21.2761 15.5 21.5 15.7239 21.5 16V19C21.5 19.663 21.2366 20.2989 20.7678 20.7678C20.2989 21.2366 19.663 21.5 19 21.5H16C15.7239 21.5 15.5 21.2761 15.5 21C15.5 20.7239 15.7239 20.5 16 20.5H19C19.3978 20.5 19.7794 20.342 20.0607 20.0607C20.342 19.7794 20.5 19.3978 20.5 19V16C20.5 15.7239 20.7239 15.5 21 15.5Z"
                className="Vector (Stroke)"
            />
        </svg>
    );
};

export default Widget;
