import { Button } from 'react-rainbow-components';
import useCreateApps from 'data/hooks/useCreateApps';
import { StyledPlusIcon, Title, TitleContainer } from './styled';

const AppsHeader = () => {
    const openCreateApp = useCreateApps();
    return (
        <TitleContainer>
            <Title>Applications</Title>
            <Button variant="brand" borderRadius="semi-rounded" onClick={openCreateApp}>
                <StyledPlusIcon />
                New Application
            </Button>
        </TitleContainer>
    );
};

export default AppsHeader;
