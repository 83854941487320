import { PhoneNumberParts } from '@transactional-message-tool/shared';
import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

const getPhoneNumberParts = (value: string) => {
    const number = phoneUtil.parseAndKeepRawInput(value, 'US');
    const countryCode = number.getCountryCode();
    const nationalNumber = number.getNationalNumber();
    const formattedNumber = phoneUtil.format(number, 0);
    const isoCode = phoneUtil.getRegionCodeForCountryCode(countryCode as number);
    const countryCodeString = countryCode?.toString();
    return {
        countryCode: countryCodeString,
        formattedNumber,
        isoCode,
        nationalNumber,
    } as PhoneNumberParts;
};

export default getPhoneNumberParts;
