import { Timestamp } from 'firebase/firestore';
import formatter from 'data/services/date/formatter';
import { Container } from './styled';

interface DateTimeColumnProps {
    value?: Timestamp;
    className?: string;
}

const DateTimeColumn = (props: DateTimeColumnProps) => {
    const { value, className } = props;
    if (!value) return null;
    return <Container className={className}>{formatter.format(value?.toDate())}</Container>;
};

export default DateTimeColumn;
