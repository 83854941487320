import { ButtonIcon } from 'react-rainbow-components';
import { UniversalFormModal } from '@rainbow-modules/forms';
import { useConnectModal } from '@rainbow-modules/hooks';
import { Plus } from '@rainbow-modules/icons';
import useGroups from 'data/hooks/useGroups';
import { useNavigate, useParams } from 'react-router-dom';
import useCreateGroups from 'data/hooks/useCreateGroups';
import GroupItem from './groupItem';
import GroupForm from './groupForm';
import { GroupHeader, GroupListTitle } from './styled';
import { GROUP_MODAL } from '../../../constants';

const GroupList = () => {
    const navigate = useNavigate();
    const connectedModalProps = useConnectModal(GROUP_MODAL);
    const { appId, groupId } = useParams();
    const [groups] = useGroups(appId as string);
    const openCreateGroup = useCreateGroups(appId as string);

    return (
        <div className="rainbow-m-right_small rainbow-p-top_large">
            <GroupHeader>
                <GroupListTitle>Groups</GroupListTitle>
                <ButtonIcon
                    variant="outline-brand"
                    size="small"
                    icon={<Plus />}
                    borderRadius="semi-rounded"
                    onClick={openCreateGroup}
                />
            </GroupHeader>
            <ul>
                {groups.map((group) => (
                    <GroupItem
                        isSelected={group.id === groupId}
                        key={group.id}
                        groupName={group.name}
                        description={group.reservedNumber.friendlyName}
                        onClick={() => navigate(group.id)}
                    />
                ))}
            </ul>
            <UniversalFormModal
                borderRadius="semi-rounded"
                fields={GroupForm}
                {...connectedModalProps}
            />
        </div>
    );
};

export default GroupList;
