const formatter = new Intl.PluralRules('en-US', { type: 'ordinal' });

interface Params {
    value: number;
    singularSuffix: string;
    pluralSuffix: string;
}

const formatPlural = ({ value, singularSuffix, pluralSuffix }: Params) => {
    const rule = formatter.select(value);
    return rule === 'one' ? `${value} ${singularSuffix}` : `${value} ${pluralSuffix}`;
};

export default formatPlural;
