import { ApiKey, ApiKeyCreate } from '@transactional-message-tool/shared';
import { useMutation, useQueryClient } from 'react-query';
import { hideAppSpinner, showAppNotification, showAppSpinner } from '@rainbow-modules/app';
import { useOpenModal } from '@rainbow-modules/hooks';
import createApiKey from 'data/services/apiKeys/create';
import ModalTitle from 'components/ModalTitle';

const useCreateApiKeys = (appId: string) => {
    const queryClient = useQueryClient();
    const [openApiKeyModal, closeApiKeyModal] = useOpenModal('api-key');

    const { mutate: addApiKey, isLoading: isMutationLoading } = useMutation(createApiKey, {
        onMutate: () => {
            showAppSpinner();
        },
        onSuccess: (result: ApiKey) => {
            const newKey = {
                new: true,
                ...result,
            };

            queryClient.setQueryData<ApiKey[]>(`/apps/${appId}/apikeys`, (oldData) => {
                return [newKey, ...(oldData || [])];
            });
            closeApiKeyModal();
            hideAppSpinner();
            showAppNotification({
                title: 'API Key created successfully',
                description: 'You can now use this key to authenticate API requests.',
                icon: 'success',
                timeout: 5000,
            });
        },
        onError: () => {
            hideAppSpinner();
            showAppNotification({
                title: 'API Key creation failed',
                description: 'Please try again later.',
                icon: 'error',
                timeout: 5000,
            });
        },
    });

    const openCreateApiKey = () =>
        openApiKeyModal({
            title: ModalTitle({
                title: 'Create new API key',
                description:
                    'API keys are revokable credentials for the reTalkable API. You can use API keys to authenticate.',
            }),
            submitButtonLabel: 'Generate API Key',
            onSubmit: (values: ApiKeyCreate) => {
                addApiKey({ values, appId });
            },
        });

    return {
        openCreateApiKey,
        isMutationLoading,
    };
};

export default useCreateApiKeys;
