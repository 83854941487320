import { isRequired, composeValidators } from '@rainbow-modules/forms';
import isPhoneNumber from 'helpers/isPhoneNumberValidator';
import { Field } from 'react-final-form';
import { Input, PhoneInput } from 'react-rainbow-components';

const ContactForm = () => {
    return (
        <div className="rainbow-m-horizontal_large">
            <Field
                name="firstName"
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={Input}
                validate={isRequired()}
                label="First Name"
                required
                placeholder="Enter the contact first name"
                className="rainbow-m-bottom_large"
                borderRadius="semi-rounded"
                labelAlignment="left"
            />
            <Field
                name="lastName"
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={Input}
                validate={isRequired()}
                label="Last Name"
                required
                placeholder="Enter the contact last name"
                className="rainbow-m-bottom_large"
                borderRadius="semi-rounded"
                labelAlignment="left"
            />
            <Field
                name="phone"
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={PhoneInput}
                validate={composeValidators(isRequired(), isPhoneNumber())}
                required
                label="Phone Number"
                placeholder="Enter the contact phone number"
                className="rainbow-m-bottom_large"
                borderRadius="semi-rounded"
                labelAlignment="left"
            />
        </div>
    );
};

export default ContactForm;
