import { RecordField } from '@rainbow-modules/record';
import useApp from 'data/hooks/useApp';
import { useParams } from 'react-router-dom';
import DateTimeColumn from 'components/DateTimeColumn';
import {
    Container,
    IdContainer,
    StyledCopyToClipboardButton,
    StyledGroupCountColumn,
    StyledRecordDetails,
} from './styled';
import Actions from '../settingsActions';

const GeneralDetails = () => {
    const { appId } = useParams();
    const [app] = useApp(appId);

    return (
        <Container>
            <StyledRecordDetails>
                <RecordField label="Application Name:" value={app?.name} />
                <IdContainer>
                    <RecordField label="Application Id:" value={app?.id} />
                    <StyledCopyToClipboardButton value={app?.id} />
                </IdContainer>
                <RecordField label="Authentication URL:" value={app?.authUrl} />
                <RecordField
                    label="Created At:"
                    value={<DateTimeColumn value={app?.createdAt} />}
                />
                <RecordField label="Groups:" value={<StyledGroupCountColumn row={app} />} />
                {/* <RecordField label="Contacts:" value="" /> */}
            </StyledRecordDetails>
            <Actions app={app} />
        </Container>
    );
};

export default GeneralDetails;
