/* eslint-disable no-param-reassign */
import axios from 'axios';
import Qs from 'qs';
import { auth } from '../../../firebase';

const baseURL = process.env.REACT_APP_BASE_URL || 'http://localhost:8080';

const BackendClient = axios.create({
    baseURL,
});

BackendClient.interceptors.request.use(async (config) => {
    const token = await auth.currentUser?.getIdToken();

    if (config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
    } else {
        // @ts-ignore
        config.headers = {
            Authorization: `Bearer ${token}`,
        };
    }

    // @ts-ignore
    config.paramsSerializer = (params) => {
        return Qs.stringify(params, {
            arrayFormat: 'brackets',
            encode: true,
        });
    };

    return config;
});

export default BackendClient;
