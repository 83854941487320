import useIdToken from 'data/hooks/useIdToken';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { RenderIf } from 'react-rainbow-components';
import { Outlet, useParams } from 'react-router-dom';
import { ResizableColumns } from '@rainbow-modules/layout';
import LeftBar from './leftBar';
import { Container } from './styled';

const Widget = () => {
    const idToken = useIdToken();
    const { appId } = useParams();
    useEffect(() => {
        return () => {
            const element = document.getElementById('retalkable-app-container');
            if (element) {
                element.remove();
            }
        };
    }, []);
    return (
        <Container>
            <RenderIf isTrue={idToken}>
                <Helmet>
                    <script
                        src={`${process.env.REACT_APP_BASE_URL}/widget?appId=${appId}&token=${idToken}`}
                        crossOrigin="anonymous"
                        async
                    />
                </Helmet>
            </RenderIf>
            <ResizableColumns
                initialDividerPosition={250}
                leftColumn={<LeftBar />}
                minLeftWidth={{ px: 140 }}
                rightColumn={<Outlet />}
                minRightWidth={{ percent: 25 }}
            />
        </Container>
    );
};

export default Widget;
